import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/Metaverse_cover.png'
import Metaverse_img1 from '../../assets/Capabilities/Metaverse_img1.png'
import Metaverse_img2 from '../../assets/Capabilities/Metaverse_img2.png'
import Metaverse_img3 from '../../assets/Capabilities/Metaverse_img3.png'
import Metaverse_img4 from '../../assets/Capabilities/Metaverse_img4.png'
import Metaverse_img5 from '../../assets/Capabilities/Metaverse_img5.png'
import Metaverse_img6 from '../../assets/Capabilities/Metaverse_img6.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import { useDocumentTitle } from '../../utils/useDocumentTitle'

function Metaverse() {
    useDocumentTitle("Metaverse");
    const MetaverseStats = () => {
        const stats = [
            { value: '81%', description: 'of executives say the metaverse will have a positive impact on their organizations' },
            { value: '90%', description: 'of consumers are curious about the metaverse and its potential applications in their daily lives' },
            { value: '$1T', description: 'Estimated annual revenue opportunity for the metaverse across various sectors by 2030' },
        ];

        return <StatsGrid title="Why reinvent learning?" stats={stats} />;
    };

    const ReinventWithEnterprisePlatforms = () => {
        const cards = [
            {
                title: 'Blockchain and Web3',
                description: 'Revolutionize operations with decentralized solutions',
                hasButton: true,
            },
            {
                title: 'Industrial Spatial Computing',
                description: 'A new wave of industrial revolution is coming',
                hasButton: true,
            },
            {
                title: 'Consumer Spatial Computing',
                description: 'Make every experience an immersive experience',
                hasButton: true,
            },
            {
                title: 'Decentralized Identity',
                description: 'Empowerment through ownership',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with us" cards={cards} />;
    };

    const slides = [
        {
            title: "More than hype—here's how to embrace the metaverse today",
            image: Metaverse_img1,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "How to build a responsible metaverse—starting now",
            image: Metaverse_img2,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Climate change gets real in the metaverse",
            image: Metaverse_img3,
            gradient: "from-cyan-400 to-cyan-600"
        }
    ];

    const MetaverseTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending?" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "DIAA CORP Named a Leader in Innovation Consulting by Forrester, Q2 2024",
                image: Metaverse_img4,
                alt: "DIAA CORP Innovation Consulting Leader"
            },
            {
                title: "Leader in enterprise blockchain services by everest group 2023",
                image: Metaverse_img5,
                alt: "Enterprise Blockchain Services Leader"
            },
            {
                title: "Recognized as Market Leader for Metaverse Service by HFS Horizons",
                image: Metaverse_img6,
                alt: "Metaverse Service Market Leader"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Welcome to new realities"
                description="The physical and virtual worlds are blending. The metaverse offers new ways to connect, create and consume. And it’s a fresh chance to generate totally inclusive, responsible experiences."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithEnterprisePlatforms />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
            {/* Add more components and content here */}
        </div>
    )
}

export default Metaverse
