import React from 'react';
import { motion } from 'framer-motion';
import { Header } from '../components/resuable/SapSolution';
import Footer from '../components/Footer';
import { LightbulbIcon } from 'lucide-react';

// Import images (replace with actual paths)
import headerImage from '../assets/SAP/sap-architectural-consulting-header.png';
import architectureImage from '../assets/SAP/architecture-image.png';
import evaluatingImage from '../assets/SAP/evaluating-image.png';
import assessingImage from '../assets/SAP/assessing-image.jpg';
import evaluateImage from '../assets/SAP/Evaluate.jpg';
import { ContactContent } from '../components/resuable/SapComponents';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SAPArchitecturalConsulting = () => {

  useDocumentTitle('SAP Architectural Consulting');


  const ArchitectureCard = () => {
    return (
      <div className="bg-gradient-to-r from-[#1D1F3F] via-[#070727] to-[#1D1F3F] text-white p-4 sm:p-6 rounded-lg shadow-lg mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-4 sm:gap-6">
          {/* Left Image */}
          <div className="lg:w-1/3">
            <div className="bg-blue-500 h-0 pb-[100%] w-full rounded-lg overflow-hidden relative">
              <img
                src={architectureImage}
                alt="Architecture illustration 1"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Center Content */}
          <div className="lg:w-1/3 text-center">
            <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4">We Start With the Architecture</h2>
            <p className="text-sm sm:text-base mb-2 sm:mb-4">
              When an organization frequently faces issues with their software - glitches, crashes, user dissatisfaction, or even falling short of deadlines - DIAA Corp typically encourages the stakeholders to take a good look at the underlying architecture.
            </p>
            <p className="text-sm sm:text-base">
              As an SAP Architecture consulting partner, DIAA Corp works with organizations to co-develop and co-architect software solutions, helping them identify right architecture elements and suggestions on process mining. Our teams work closely with your product/solution development teams on how to build a robust architecture which will serve as the foundation for building the SAP solutions.
            </p>
            <div className="mt-4 sm:mt-6 flex items-center justify-center">
              <LightbulbIcon className="w-5 h-5 sm:w-6 sm:h-6 text-yellow-400 mr-2" />
              <span className="text-xs sm:text-sm">DIAA Corp Architecture Consulting</span>
            </div>
          </div>

          {/* Right Image */}
          <div className="lg:w-1/3">
            <div className="bg-purple-500 h-0 pb-[100%] w-full rounded-lg overflow-hidden relative">
              <img
                src={evaluatingImage}
                alt="Architecture illustration 2"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InformationSystemOverview = () => {
    // const [fadeIn, setFadeIn] = useState(true);
    // console.log(cards[0].items.length, "cards");
    return (
      <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-12 md:w-11/12 xl:w-10/12`}>
        <div className="px-4 sm:px-8 md:px-12 py-8 sm:py-12 text-center">
          <p className="text-sm sm:text-base md:text-lg">Any information system ought to be built by taking into consideration various aspects, such as business logic, corporate governance, industry practices and market trends. The development of such systems is typically driven by a roadmap and wisely planned timelines.
            DIAA Corp has a team of consultants with expertise in SAP solution architecture that can help your organization achieve its development goals.</p>
        </div>
      </div>
    );
  }


  const EvaluatingArchitectureCard = () => {
    return (
      <div className="flex flex-col md:flex-row bg-gradient-to-r from-[#1D1F3F] to-[#070727] p-4 sm:p-6 rounded-lg shadow-lg mt-8 sm:mt-12 xl:mt-24 mx-auto w-full">
        <div className="md:w-1/4 mb-4 md:mb-0">
          <div className="rounded-lg overflow-hidden border-4 border-blue-900 h-full">
            <img
              src={evaluateImage}
              alt="Person evaluating architecture"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
        <div className="md:w-3/4 md:pl-4 sm:pl-6 text-gray-300">
          <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-center md:text-left">Evaluating the Architecture</h2>
          <p className="text-sm sm:text-base md:text-lg leading-relaxed">
            An architecture that is robust typically undergoes multiple iterations.
            Claude's expertise comes in handy in determining how an architecture can
            be effectively evaluated. Our teams work with client developers to improve
            architecture and establish the skills needed in-house to build systems that
            incorporate requisite critical components. We offer the tools and best
            practices to iteratively develop secure architectures. Our consultants take into
            consideration the quality attributes and tradeoffs if any. We also lookout for
            risks, shortcomings, and inherent flaws within the system. This is critical since
            any organization relies on multiple systems that interact with or are
            connected to each other. Data security and the ease of maintenance is well
            considered too, so that the system can win stakeholder trust at the early
            stages itself.
          </p>
        </div>
      </div>
    );
  };

  const AccessingTheArchitect = () => {
    return (
      <div className="flex flex-col md:flex-row bg-gradient-to-l from-[#1D1F3F] to-[#070727] p-4 sm:p-6 rounded-lg shadow-lg mt-8 sm:mt-12 xl:mt-24 mx-auto w-full">
        <div className="md:w-3/4 md:pr-4 sm:pr-6 text-gray-300 order-2 md:order-1">
          <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-center md:text-left">Accessing the Architect</h2>
          <p className="text-sm sm:text-base md:text-lg leading-relaxed">
            Our team of expert architects is readily available to assist you throughout your project. 
            We provide direct access to experienced professionals who can guide you through complex 
            architectural decisions, offer insights on best practices, and help you navigate challenges. 
            Our architects work closely with your team, ensuring that you have the support you need 
            to develop robust, scalable, and efficient SAP solutions. Whether you need advice on 
            system integration, performance optimization, or security enhancements, our architects 
            are just a call away, ready to provide tailored guidance for your specific needs.
          </p>
        </div>
        <div className="md:w-1/4 mb-4 md:mb-0 order-1 md:order-2">
          <div className="rounded-lg overflow-hidden border-4 border-blue-900 h-full">
            <img
              src={assessingImage}
              alt="Accessing the architect"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </div>
    );
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="bg-bg text-white">
      <Header
        title="SAP Architectural Consulting"
        description="Building the Architectural essential for software and information systems that is reliable, responsive, and scalable."
        backgroundImage={headerImage}
        isExploreBtn={true}
      />
      <InformationSystemOverview />
      <main className="mx-auto py-12">
        <div className='mb-12 lg:w-11/12 mx-auto'>
          <ArchitectureCard />
        </div>

        <motion.section {...fadeInUp} className="my-16 flex flex-col justify-center items-center text-center bg-[#494D624D] py-12 px-4 sm:px-6 lg:px-8 mx-auto">
          <div className='w-full sm:w-4/5 lg:w-3/4 xl:w-1/2 mx-auto'>
            <h2 className="text-2xl sm:text-3xl font-bold mb-6">Conceptualizing the Architecture</h2>
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed mb-4">
              CloudSC believes in steady development of architectures and conservation strategies to satisfy the base requirements. Our team puts across multiple options to be explored by the stakeholder, with the winning option decided after careful analysis and deliberation. CloudSC consultants then work with the client team to develop the final architecture, developing the use cases, UX processes, and product workflows in tandem.
            </p>
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
              We work with the product developers to prototype the envisioned product. This joint effort between our architects and the developers yields results that provide satisfaction to the client stakeholders.
            </p>
          </div>
        </motion.section>

        <div className='mb-12 lg:w-11/12 mx-auto'>
          <EvaluatingArchitectureCard />
        </div>

        <div className='mb-12 lg:w-11/12 mx-auto'>
          <AccessingTheArchitect />
        </div>

        <div>
          <ContactContent title={`Develop software solutions that are reliable and firm by taking a good look at the underlying architecture. We can help you assess the current state and develop a roadmap to achieve a robust architecture based on the envisioned business goals.`} />
        </div>

      </main>

      <Footer />
    </div>
  );
};

export default SAPArchitecturalConsulting;