import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/AMSCover.png'
import AMS_img_1 from '../assets/SAP/AMS_img_1.png'
import AMS_img_2 from '../assets/SAP/AMS_img_2.png'
import AMS_img_3 from '../assets/SAP/AMS_img_3.png'
import AMS_img_4 from '../assets/SAP/AMS_img_4.png'
import AMS_Bussiness_cover from '../assets/SAP/AMS_Bussiness_cover.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice,
  faLink,
  faHandshake,
  faDatabase,
  faUsers,
  faChartLine,
  faWarehouse,
  faUserTie,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons';
import { Database, Monitor, Server, Columns, Grid, Table, HardDrive, Layout, PieChart, Globe, BarChart } from 'lucide-react';
import { ContactContent, scrollToBottom } from '../components/resuable/SapComponents'
import { AnimatePresence, motion } from 'framer-motion'
import { FaCloud } from 'react-icons/fa';
import { IoCloudOutline } from 'react-icons/io5';
import { MdCloudDone } from 'react-icons/md';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'



function SAPApplicationManagementServices() {

  useDocumentTitle("SAP Application Management Services(AMS)");


  const InformationSystemOverview = () => {
    return (
      <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 lg:-mt-40 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-12 md:w-11/12 xl:w-10/12`}>
        <div className="lg:px-12 py-12 text-center flex flex-col items-center">
          <p className="mb-4">"Organizations of different landscapes, in the journey of implementing sophisticated enterprise software solutions, often ignore the real value of the implemented solutions."</p>
          <p className="text-theme font-bold">Don't be that guy!</p>
        </div>
      </div>
    );
  }

  const BusinessTechLayout = () => {
    return (
      <div className="w-11/12 mx-auto mt-8 sm:mt-12 xl:mt-24 text-white p-4 sm:p-8 flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/3 lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Business technology models continue evolving</h2>
          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">The pace of change is accelerating, and real-time insights are becoming a critical component of business success and, most importantly, business survival.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">A major part of digital transformation includes moving to an innovative digital core, which typically is an organization's ERP system. Careful planning, which includes choosing the right implementation partner, aligning the initiative with advanced tools, a portfolio of services and a library of best practices and lessons learned, is required to make the move easier.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">Perhaps one of the most overused words and common misconceptions is that once the system is implemented, that is it! Also, it is assumed that benefits are achieved right away, and no new needs will come up.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">These common assumptions have a profound impact on the application's return on investment (ROI), along with user adoption and overall application workflows.</p>
        </div>

        <div className="w-full lg:w-1/3 flex flex-wrap justify-center items-center gap-4">
          <div className="w-full h-48 sm:h-64 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_1} alt="Business chart" className="w-full h-full object-cover" />
          </div>

          <div className="w-5/12 h-36 sm:h-48 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_2} alt="Business meeting" className="w-full h-full object-cover" />
          </div>

          <div className="w-5/12 h-36 sm:h-48 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_3} alt="Technology concept" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    );
  };

  const infoCardItems = [
    {
      title: "Trusted by 60 of Fortune 500 Companies",
    },
    {
      title: "Presence in 26 Countries 50 Global Locations",
    },
    {
      title: "Serving 25 Industry Verticals",
    },
  ]
  const infoCardItems2 = [
    {
      title: "Manufacturing",
    },
    {
      title: "Retail",
    },
    {
      title: "Life science",
    },
    {
      title: "Hi - Tech",
    },
    {
      title: "Financial services",
    },
  ]

  const InfoCard = ({ title }) => (
    <div className="flex flex-col bg-[#191F38] m-2 sm:m-4 md:m-6 rounded-lg drop-shadow-xl w-full sm:w-60 h-40 text-center justify-center p-4">
      <h2 className="text-base sm:text-lg md:text-xl font-semibold">{title}</h2>
    </div>
  );

  const DiaaDashboard = () => {
    const services = [
      "Application Evolution & Change Management",
      "Functional Application Support",
      "SAP Application Basis Support",
      "SAP HANA DB Management",
      "Operating System (OS) Maintenance",
      "Virtualization",
      "Servers",
      "Storage and Networking",
      "Vendor Responsibility limited to Application Management Services",
      "Vendor Responsibility for ongoing technical operations and maintenance",
      "Vendor Responsibility limited to infrastructure"
    ];

    const managementAreas = [
      { title: "Quality", value: "ISO 9001" },
      { title: "Security", value: "BS7799 / ISO27001" },
      { title: "IT SCM", value: "ISO 22301" },
      { title: "Service Management", value: "ITIL/ISO 20000" },
      { title: "Overall Framework", value: "COBIT, ISACA" },
      { title: "Guaranteed Availability", value: "99.5%" },
      { title: "Global Support", value: "24x7" }
    ];

    return (
      <div className="text-white p-8 w-11/12 mx-auto mt-12 xl:mt-24">
        <h1 className="text-2xl font-bold mb-6 md:mb-12 text-center">
          DIAA CORP Full Suite of Application Management Services
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12 mx-auto max-w-5xl">
          <ul className="flex flex-col items-center">
            {services.slice(0, 8).map((service, index) => (
              <li key={index} className="flex items-start mb-3 w-full">
                <span className="w-3 h-3 rounded-full mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                {service}
              </li>
            ))}
          </ul>
          <ul className="flex flex-col items-center">
            {services.slice(8).map((service, index) => (
              <li key={index} className="flex items-start mb-3 w-full">
                <span className="w-3 h-3 rounded-full mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                {service}
              </li>
            ))}
          </ul>
        </div>

        <h2 className="text-xl font-bold mb-6 text-center mt-12 xl:mt-24">
          DIAA CORP manages complete stack
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-12 w-11/12 mx-auto">
          {managementAreas.map((area, index) => (
            <div key={index} className="bg-[#1C1E3B] rounded-lg p-4 text-center">
              <p className="text-xs sm:text-sm md:text-base mb-2 text-white">{area.title}</p>
              <p className="text-sm sm:text-base md:text-lg text-theme font-semibold">{area.value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const LinesOfBusinesses = () => {
    return (
      <div className="relative w-full bg-cover bg-center flex items-center justify-center pt-8 sm:pt-12 md:pt-24 mt-8 sm:mt-12 xl:mt-24">
        <div className="absolute top-0 left-0 w-full h-full">
          <div
            style={{
              background: `url(${AMS_Bussiness_cover})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              filter: 'blur(2px)',
              opacity: 0.7,
            }}
            className="w-full h-full"
          ></div>
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white text-center w-full px-4 md:w-4/5 mx-auto">
          <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 sm:mb-8">Lines Of Businesses We Serve</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 sm:my-8 md:my-16 lg:my-24">
            {[
              { icon: <FontAwesomeIcon icon={faFileInvoice} />, title: 'Enterprise Resource Planning (ERP)' },
              { icon: <FontAwesomeIcon icon={faLink} />, title: 'Supply Chain Management (SCM)' },
              { icon: <FontAwesomeIcon icon={faHandshake} />, title: 'Supplier Relationship Management (SRM)' },
              { icon: <FontAwesomeIcon icon={faDatabase} />, title: 'Master Data Governance (MDG)' },
              { icon: <FontAwesomeIcon icon={faUsers} />, title: 'Customer Relationship Management (CRM)' },
              { icon: <FontAwesomeIcon icon={faChartLine} />, title: 'Business Intelligence/ Business Objects (BI/BO)' },
              { icon: <FontAwesomeIcon icon={faWarehouse} />, title: 'Extended Warehouse Management (EWM)' },
              { icon: <FontAwesomeIcon icon={faUserTie} />, title: 'Human Capital Management (HCM)' },
              { icon: <FontAwesomeIcon icon={faMoneyBillWave} />, title: 'Finance' },
            ].map((item, index) => (
              <div key={index} className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-md p-4 rounded-lg flex flex-col items-center h-full">
                <span className='text-theme text-2xl mb-4'>
                  {item.icon}
                </span>
                <div className="text-center">
                  <h3 className="font-semibold text-sm sm:text-base md:text-lg">{item.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };



  const SupportedSystemsDisplay = () => {
    const osDbSystems = [
      { name: 'Unix Flavours', icon: Database },
      { name: 'Windows Family', icon: Monitor },
      { name: 'SAP HANA', icon: Server },
      { name: 'Oracle', icon: Columns },
      { name: 'MaxDB & DB2', icon: Grid },
      { name: 'MS SQL', icon: Table },
      { name: 'Sybase', icon: HardDrive },
    ];

    const sapComponents = [
      { name: 'Frontend Technologies including Persona, Fiori & UIS, SAPUI5', icon: Layout },
      { name: 'SAP Business Suite', icon: PieChart },
      { name: 'SAP NetWeaver Technology', icon: Globe },
      { name: 'SAP HANA Technology', icon: BarChart },
    ];

    const SystemButton = ({ name, Icon, fullWidth = false }) => (
      <div className={`bg-gray-800 rounded-t-2xl rounded-b-2xl p-4 flex flex-col items-center space-y-2 ${fullWidth ? 'col-span-2' : ''}`}>
        <div className="w-12 h-12 bg-custom-gradient rounded-full flex items-center justify-center mb-2">
          <Icon className="text-white" size={24} />
        </div>
        <span className="text-white text-center text-sm">{name}</span>
      </div>
    );

    const ContactContent3 = ({ title, sub }) => {
      return (
        <div className='flex flex-col justify-center items-center text-center bg-[#14192E] mobile:h-[293px] md:h-[471px] px-4 relative'>
          <div className='md:w-11/12 relative z-10'>
            <h2 className="text-lg md:text-2xl font-bold mb-4">{title}</h2>
            <p className='mb-12 mobile:text-sm'>{sub}</p>
            <motion.button
              className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={scrollToBottom}
            >
              Contact us
            </motion.button>
          </div>
        </div>
      );
    }

    return (
      <div className="p-4 sm:p-8 text-white w-11/12 mx-auto mt-8 sm:mt-12 xl:mt-24">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">OS/DB We Support</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {osDbSystems.map((system, index) => (
            <SystemButton key={index} name={system.name} Icon={system.icon} />
          ))}
        </div>

        <h2 className="text-2xl font-bold mb-6">SAP Platform Components We Support</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {sapComponents.map((component, index) => (
            <div key={index} className="bg-gray-800 rounded-2xl p-6 flex flex-col items-center space-y-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-custom-gradient">
                <component.icon className="text-white" size={32} />
              </div>
              <span className="text-white text-center text-sm font-medium">{component.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const FlexibleEngagementModels = () => {
    return (
      <div className="text-white p-4 sm:p-8 flex flex-col items-center mt-8 sm:mt-12 xl:mt-24">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">Flexible Engagement Models</h2>
        <p className="text-sm sm:text-base mb-4 sm:mb-6 max-w-3xl text-center text-[#F1F1F1]">
          DIAA Corp's four different levels of packaged application management services for SAP Applications, make it easy for organizations to manage frequent software releases, expand functionality, and simultaneously reduce cost.
        </p>
        <p className="text-sm sm:text-base mb-6 sm:mb-8 max-w-3xl text-center text-[#F1F1F1]">
          The models are differentiated based on the responsibility handled, location of the team, benefits derived, pricing and accountability for measured (and customised) business and IT outcomes. To know more, please download the DIAA Corp SAP AMS brochure.
        </p>
        <div className="flex flex-wrap justify-center gap-6 w-full max-w-4xl">
          {[
            { title: "Augmentation", icon: "🖼️", color: "bg-purple-600" },
            { title: "Co-sourcing", icon: "💱", color: "bg-blue-600" },
            { title: "Managed Service", icon: "🔧", color: "bg-green-600" },
            { title: "Outsourced", icon: "🔄", color: "bg-red-600" },
          ].map((item, index) => (
            <div key={index} className="bg-[#14192E] rounded-2xl p-4 flex flex-col items-center space-y-3 border border-gray-700 w-[calc(50%-12px)] sm:w-[calc(25%-18px)]">
              <div className={`${item.color} rounded-full p-3 w-12 h-12 flex items-center justify-center`}>
                <span className="text-2xl">{item.icon}</span>
              </div>
              <span className="text-sm font-semibold text-center">{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const Switch2Cloud = () => {
    return (
      <div className="text-white flex items-center justify-center p-4 mt-8 sm:mt-12 xl:mt-24">
        <div className="w-11/12 2xl:w-10/12 mx-auto">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-4 sm:mb-6 xl:mb-12">
            Accelerate Your Digital Transformation with DIAA Corp Switch2Cloud
          </h1>
          <p className="text-sm sm:text-base text-center mb-6 sm:mb-8 text-gray-300 xl:mb-12">
            DIAA CORP Switch2Cloud seamless migration and management services portfolio, helps you fast-track your move to SAP S/4HANA – by helping you accelerate execution, increase business agility, and improve productivity without business disruption. It comprises of SAP Managed Services, S/4 Transformation services and SAP AMS, all bundled together to provide a seamless experience to our customers.
          </p>

          <div className="bg-[#10142C] rounded-3xl p-8 mb-12 py-12 xl:py-24 backdrop-filter backdrop-blur-sm">
            <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
              Package to Accelerate customers adoption of
            </h2>
            <p className="text-xl md:text-2xl font-bold text-center mb-12">S/4HANA in Cloud</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
              {[
                { title: "SAP Managed Services", Icon: FaCloud },
                { title: "SAP S/4HANA Conversion", Icon: IoCloudOutline },
                { title: "Application Management", Icon: MdCloudDone },
              ].map((item, index) => (
                <div key={index} className="relative group">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-600 rounded-3xl blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                  <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                    <div className="w-24 h-24 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 flex items-center justify-center">
                      <item.Icon className="text-white text-5xl" />
                    </div>
                    <span className="text-lg font-medium text-center text-white">{item.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };


  const DashboardItem = ({ number, text, className }) => (
    <div className={`bg-gray-800 rounded-lg p-4 flex flex-col justify-between ${className}`}>
      <div className="text-purple-500 text-3xl font-bold">{number}</div>
      <div className="text-white text-sm mt-2">{text}</div>
    </div>
  );

  const Dashboard = () => (
    <div className="text-white p-4 sm:p-8 text-center">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-8">Why DIAA CORP For SAP AMS?</h1>
      <p className="text-lg sm:text-xl mb-8 sm:mb-12">Choosing Us Will Bring Value In Following Ways</p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {[
          { number: "250+", text: "SAP Consultants Globally", className: "md:col-span-2" },
          { number: "25", text: "Centers of Excellence around App development and Excellence", className: "md:row-span-2" },
          { number: "1000+", text: "SAP Projects Handled", className: "" },
          { number: "75+", text: "Application Management & Support", className: "md:col-span-2" },
          { number: "100+", text: "Advisory Services Engagements and 75+ Optimization Projects", className: "md:col-span-2" },
          { number: "75+", text: "Upgrade Planning and Analysis & 20+ Upgrades", className: "" },
          { number: "1000+", text: "SAP Production workloads", className: "md:col-span-2" },
          { number: "550+", text: "Migration and Managed services projects", className: "" },
          { number: "475+", text: "SAP Implementations", className: "md:col-span-2" },
        ].map((item, index) => (
          <div key={index} className={`bg-[#1C1E3B] rounded-lg p-6 flex flex-col justify-between ${item.className}`}>
            <div className="text-4xl font-bold mb-4 bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent">
              {item.number}
            </div>
            <div className="text-sm text-gray-300">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const ServiceCard = ({ text }) => (
    <div className="bg-[#1C1E3B] rounded-lg p-4 flex items-center justify-center h-24">
      <p className="text-white text-center text-sm">{text}</p>
    </div>
  );

  const SupportServices = () => (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <ServiceCard text="L1, L2 Support" />
        <ServiceCard text="L3 and L4 Support Incident Support" />
        <ServiceCard text="Release and Configuration Management" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ServiceCard text="Testing Support Services" />
        <ServiceCard text="Minor and Major Enhancements" />
      </div>
    </div>
  );


  return (
    <div className="bg-bg text-white">
      <Header
        title="DIAA CORP AI Powered SAP Application Management Services(AMS)"
        description="DIAA CORP’s SAP AMS are designed to help your organization use smart, efficient ways to optimize and generate more value from application portfolios. The services can range from on-demand SAP support through full application outsourcing and managed infrastructure (on-premise and/or cloud)."
        backgroundImage={headerImage}
        isExploreBtn={true}
      />
      <InformationSystemOverview />
      <div>
        <BusinessTechLayout />
      </div>


      <div>

        <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
          <h2 className="text-2xl font-semibold text-center mb-4">
            Why DIAA CORP is a Trusted SAP Partner?
          </h2>
          <h3 className="text-lg font-medium  mb-6 text-[#C9C9C9F1]">
            Achieving Application Objectives With A Strategic Approach
          </h3>

          <p className="text-base leading-7 mb-6 text-[#F1F1F1]">
            DIAA CORP SAP Center of Excellence (COE) specializes in delivering sturdy and pliable application management services (AMS) to an organization’s IT and business objectives.
          </p>

          <p className="text-base leading-7 text-[#F1F1F1]">
            As part of SAP Application Management Services (SAP AMS), our in-house and global gamut of the delivery network enables our clients to have unhindered access to the correct resources and skill sets, along with the proven industry experience to resolve our customer's strategic application management objectives.          </p>

        </div>

      </div>

      <div>
        <div className='flex flex-col justify-center items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8'>
          <div className='w-full sm:w-11/12'>
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">One of the leading SAP partners with implementations across the world</h2>
          </div>
        </div>

        <div className="relative z-10 -mt-16 sm:-mt-20 container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-6">
            {infoCardItems.map((item, index) => (
              <InfoCard key={index} title={item.title} />
            ))}
          </div>
        </div>
      </div>

      <div>
        <DiaaDashboard />
      </div>

      <div>
        <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
          <h2 className="text-2xl font-semibold text-center mb-4">
            DIAA Corp Services Portfolio          </h2>
          <h3 className="text-lg font-medium mb-6 text-[#C9C9C9F1]">
            Accelerating Your SAP Deployments & Migrations          </h3>

          <p className="text-base leading-7 mb-6 text-[#F1F1F1]">
            Our rich pool of certified Functional and Technical consultants provide Implementation, Development, Upgrade, Production support and Maintenance services for SAP ECC, S4H versions, SCM, Business Warehouse, CRM, GRC, Solution Manager, NetWeaver Applications, BI/BO, BPC, HANA etc., on a global delivery model. We have robust templates for Estimation, Implementation, Upgrade, Migration & support. Our accelerators around SAP System Deployments and Migrations, AMS help us to serve enterprises of any size.          </p>
        </div>
      </div>

      <div>
        <SupportServices />
      </div>


      <div>
        <div className='flex flex-col justify-start items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8'>
          <div className='w-full sm:w-11/12 mt-12 pb-12 md:pb-0'>
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">SAP Competency Overview</h2>
            <p className="text-sm sm:text-base text-[#C9C9C9F1] mb-6">Leveraging Our AMS For SAP Capability For Every Possibility</p>
            <p className="text-sm sm:text-base ">DIAA Corp SAP Application Management Services is built with the right combination of industry, technology, process, and full lifecycle assets integrated with our service delivery.</p>
          </div>
        </div>

        <div className="relative z-10 mt-12 md:mt-0 lg:-mt-20 container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-6 sm:gap-8">
            {infoCardItems2.map((item, index) => (
              <InfoCard key={index} title={item.title} />
            ))}
          </div>
        </div>
      </div>

      <div>
        <LinesOfBusinesses />
      </div>

      <div>
        <SupportedSystemsDisplay />
      </div>

      <div>
        <FlexibleEngagementModels />
      </div>

      <div className='mt-12 xl:mt-24'>
        <ContactContent title={`Not sure which engagement model is right for you?`} subtitle={`DIAA CORP offers the flexibility you’ve been looking for. We can start small with a pilot program and then customize a program that meets your specific needs and budget to ensure that your applications are running smoothly, and satisfaction levels of your end users would be our priority. Schedule a Free assessment to realize easily up to 40% reduced TCO on your SAP workloads`} />
      </div>

      <div>
        <Switch2Cloud />
      </div>

      <div>
        <Dashboard />
      </div>

      <div className='mt-12 xl:mt-24'>
        <ContactContent title={`Experience SAP And Its Impact On Business Transformation`} />
      </div>

      <div>
        <Footer />
      </div>

    </div>
  )
}

export default SAPApplicationManagementServices