import React, { useEffect, useState } from 'react'
import image1 from '../assets/SapS4hana/SAP-SuccessFactors.png'
import imageCover from '../assets/SapS4hana/SapAribaCover.png'; 
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
// import Footer from '../components/Footer';

import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapAriba = () => {
    const headerData = {
        title: "What Is SAP Ariba",
        description: "SAP Ariba is a cloud-based innovative solution that allows suppliers and buyers to connect digitally and simplify all processes end-to-end on a single platform. SAP Ariba digitally transforms your supply chain, procurement, and contract management process."
    };
    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP Ariba:",
            items: ['Energy & Natural Resources', 'Government Sector', 'Wholesale & Distribution', 'Retail & Restaurants', 'EC & O', 'Manafacturing']
        },
        {
            title: "SAP Ariba Employees:",
            items: ['Enterprise'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1],
        description: "Explore the features and functionalities of SAP Ariba through an interactive gallery."
    };

    const benefitsData = {
        title: "SAP Ariba Benefits",
        description: "",
        benefits: [
            {
                icon: '⏱️',
                title: '',
                description: 'Reduces the complexity of the procurement process while decreasing sales costs'
            },
            {
                icon: '📊',
                title: '',
                description: 'Empowers access to new suppliers that feed the purchasing cycle'
            },
            {
                icon: '📄',
                title: '',
                description: 'Supports the RFx procedure and related pricing and contracts'
            },
            {
                icon: '📈',
                title: '',
                description: 'Ensures easy management of suppliers’ catalogs'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP Ariba Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover} />
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={true} />
            <PaymentCards title={`SAP Ariba Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapAriba;