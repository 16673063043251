import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/Learning_cover.png'
// import { StatsGrid } from '../../components/resuable/CapabilitiesComponents';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import dataAi_img1 from '../../assets/Capabilities/dataAi_img1.png'
import Learning_img1 from '../../assets/Capabilities/Learning_img1.png'
import Learning_img2 from '../../assets/Capabilities/Learning_img2.png'
import Learning_img3 from '../../assets/Capabilities/Learning_img3.png'
import Enterprise_img1 from '../../assets/Capabilities/Enterprise_img1.png'
import Enterprise_img2 from '../../assets/Capabilities/Enterprise_img2.png'
import Enterprise_img3 from '../../assets/Capabilities/Enterprise_img3.png'
import Enterprise_img4 from '../../assets/Capabilities/Enterprise_img4.png'
import dataAi_img4 from '../../assets/Capabilities/dataAi_img4.png'
import dataAi_img5 from '../../assets/Capabilities/dataAi_img5.png'
import dataAi_img6 from '../../assets/Capabilities/dataAi_img6.png'
import dataAi_img7 from '../../assets/Capabilities/dataAi_img7.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const PartnersSection = () => {
    const partners = [
        { name: 'Microsoft', logo: microsoftLogo },
        { name: 'AWS', logo: awsLogo },
        { name: 'Google', logo: googleLogo },
        { name: 'SAP', logo: sapLogo },
        { name: 'IBM', logo: ibmLogo },
        { name: 'ServiceNow', logo: servicenowLogo },
        { name: 'Databricks', logo: databricksLogo },
        { name: 'Oracle', logo: oracleLogo },
    ];

    return (
        <div className="bg-[#08082A] py-12">
            <div className="max-w-6xl mx-auto px-4">
                <h2 className="text-white text-3xl font-bold text-center mb-10">Partners in change</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 items-center justify-items-center">
                    {partners.map((partner) => (
                        <img
                            key={partner.name}
                            src={partner.logo}
                            alt={`${partner.name} logo`}
                            className="h-8 object-contain"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

function Learning() {
    useDocumentTitle("Learning");
    const LearningStats = () => {
        const stats = [
            { value: '$10.3T', description: 'Total addressable market for learning and talent transformation' },
            { value: '61%', description: 'of workers rank opportunities to learn new skills as a top reason to take a new job' },
            { value: '94%', description: 'of business leaders expect employees to pick up new skills on the job' },
            { value: '5%', description: 'of organizations say they have mastered the content and technology capabilities necessary to build a future-ready workforce' },
        ];

        return <StatsGrid title="Why reinvent learning?" stats={stats} />;
    };

    const ReinventWithEnterprisePlatforms = () => {
        const cards = [
            {
                title: 'Learning strategy and design',
                description: 'Create a plan to reskill and upskill people for the AI economy',
                hasButton: true,
            },
            {
                title: 'Learning managed services',
                description: 'Reinvent learning at speed and scale with comprehensive managed services',
                hasButton: true,
            },
            {
                title: 'Learning academies',
                description: 'Accelerate learning with pre-built or custom academies focusing on AI, cloud, aerospace and more',
                hasButton: true,
            },
            {
                title: 'Learning platform',
                description: 'Our LearnVantage platform delivers AI-personalized learning pathways',
                hasButton: true,
            },
            {
                title: 'Learning certification',
                description: 'Tap into a range of industry-leading certification programs',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with enterprise platforms" cards={cards} />;
    };

    const slides = [
        {
            title: "SAP Global DIAA CORP team upskill maximize generative AI",
            image: Learning_img1,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Learning reinvented: A new blueprint for continuous, meaningful, successful change",
            image: Learning_img2,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Rise of the modern Capability Center: A reinvention engine",
            image: Learning_img3,
            gradient: "from-pink-400 to-pink-600"
        }
    ];

    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending in learning" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: dataAi_img5,
                alt: "Multicloud Managed Services"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Sarah Johnson", title: "Chief Learning Officer", imageUrl: Cloud_leader_img1 },
            { name: "David Chen", title: "Head of Digital Learning Platforms", imageUrl: Cloud_leader_img1 },
            { name: "Maria Rodriguez", title: "Director of Learning Experience Design", imageUrl: Cloud_leader_img1 },
            { name: "Alex Patel", title: "Lead Learning Analytics Specialist", imageUrl: Cloud_leader_img1 },
            { name: "Olivia Thompson", title: "Global Head of Corporate Training", imageUrl: Cloud_leader_img1 },
            { name: "Marcus Lee", title: "Director of Learning Innovation", imageUrl: Cloud_leader_img1 },
            { name: "Emily Nakamura", title: "Chief Knowledge Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Become a talent creator"
                description="While technology plays a key role in business reinvention, it’s your people who determine its success. Accenture LearnVantage helps you build the skills they need, and you want, to grow faster."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            <div>
                <LearningStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithEnterprisePlatforms />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full mt-8 md:mt-16 lg:mt-20'>
                <PartnersSection />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
            {/* Add more components and content here */}
        </div>
    )
}

export default Learning
