import React, { useState } from 'react'
import { Header } from '../components/resuable/SapSolution';
import headerImage from '../assets/SAP/Implementation_services_cover.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cover2 from '../assets/SAP/sap-imp-cover2.png';
import { IoCloudOutline, IoAppsOutline, IoBusinessOutline, IoShieldCheckmarkOutline, IoServerOutline, IoLayersOutline, IoConstructOutline, IoPeopleOutline } from 'react-icons/io5';
import { FAQItem } from '../components/resuable/SapComponents';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { IoDocumentTextOutline, IoPersonOutline, IoResizeOutline, IoSpeedometerOutline } from 'react-icons/io5';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from '../components/Footer';
import { useDocumentTitle } from '../utils/useDocumentTitle';




function SAPImplementationServices() {

    useDocumentTitle('SAP Implementation services');


    const InformationSystemOverview = () => {
        // const [fadeIn, setFadeIn] = useState(true);
        // console.log(cards[0].items.length, "cards");
        return (
            <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-12 md:w-11/12 xl:w-10/12`}>
                <div className="lg:px-12 py-12 text-center ">
                    <p className="text-sm sm:text-base md:text-lg">"Executing a SAP software implementation includes a lot of restructuring in the organization – DIAA CORP is a Certified SAP Partner. You can count on us to help with your Digital Transformation!"</p>
                </div>
            </div>
        );
    }

    const CompleteSuiteServices = () => {
        return (
            <div className="relative bg-cover bg-center flex items-center justify-center py-16">
                <div className="absolute top-0 left-0 w-full h-full"
                    style={{
                        background: `url(${cover2})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(2px)',
                    }}></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center w-11/12 mx-auto px-4 md:w-4/5">
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-12 lg:w-2/3 mx-auto">DIAA CORP offers a complete suite of services for any SAP System, Application & Product</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 my-8">
                        {[
                            { title: 'Business Consulting' },
                            { title: 'Architecture and Advisory Services' },
                            { title: 'Full Cycle Implementation' },
                            { title: 'Support and Maintenance' },
                            { title: 'Application Integration and Modernization' },
                            { title: 'ERP Migration/ Upgrade/ Modernization' },
                            { title: 'Customization and Technical Advisory' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#52527E] bg-opacity-40 backdrop-blur-md p-4 rounded-2xl flex flex-col items-center justify-center h-32 w-full">
                                <h3 className="font-semibold text-sm sm:text-base md:text-lg text-center mx-auto">{item.title}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const GradientBoxes = () => {
        const boxes = [
            '50% faster implementations, 100% SAP Activate compliant',
            'Change & release automation',
            'Agile, DevOps and Continuous delivery',
            'Robotic test automation',
            'Shared services delivery mode'
        ];

        return (
            <div className="flex flex-col items-center justify-center p-4">
                <div className="flex flex-wrap justify-center gap-4 max-w-7xl">
                    {boxes.map((text, index) => (
                        <div
                            key={index}
                            className="relative bg-[#1D1F3F] text-white p-4 rounded-2xl overflow-hidden w-64 h-24 flex items-center justify-center"
                        >
                            <div className="absolute inset-0">
                                <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#4F75FF] to-[#A100FF]" />
                                <div className="absolute right-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#A100FF] to-[#4F75FF]" />
                            </div>
                            <p className="relative z-10 text-sm sm:text-base font-medium text-center px-2">{text}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ProjectMethodology = () => {
        const stages = ['Discover', 'Prepare', 'Explore', 'Realize', 'Deploy', 'Run'];

        const Arrow = () => (
            <svg className="w-6 h-6 text-gray-400 lg:w-8 lg:h-8 transform rotate-90 xl:rotate-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
        );

        return (
            <div className="bg-[#14192EB2] flex flex-col items-center justify-center p-6 py-16 xl:py-32">
                <h2 className="text-white text-3xl md:text-4xl font-bold mb-12">Project Methodology</h2>
                <div className="flex flex-col xl:flex-row justify-center items-center gap-6 max-w-7xl">
                    {stages.map((stage, index) => (
                        <React.Fragment key={stage}>
                            <div className="relative">
                                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#4F75FF] to-[#A100FF] opacity-50 blur-sm"></div>
                                <div className="relative bg-[#13131C] text-white px-6 py-3 rounded-full border border-theme shadow-[0_0_30px_#a100ff] z-10">
                                    <p className="text-base md:text-lg font-medium">{stage}</p>
                                </div>
                            </div>
                            {index < stages.length - 1 && (
                                <div className="my-4 lg:my-0 lg:mx-4">
                                    <Arrow />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };


    const certifications = [
        { icon: <IoBusinessOutline />, title: '600+ SAP ERP Systems', subtitle: 'Managed' },
        { icon: <IoServerOutline />, title: '700+ SAP S4HANA HANA DATABASE', subtitle: 'Systems' },
        { icon: <IoCloudOutline />, title: '1200+ Managed HANA', subtitle: 'Databases' },
        { icon: <IoAppsOutline />, title: '800+ SAP FIORI Systems', subtitle: 'Managed' },
        { icon: <IoShieldCheckmarkOutline />, title: '475+ System DR Workloads', subtitle: 'Workloads' },
        { icon: <IoLayersOutline />, title: '400+ SAP Business Suite', subtitle: 'Systems' },
        { icon: <IoConstructOutline />, title: '1300+ Application', subtitle: 'Production Workloads' },
        { icon: <IoPeopleOutline />, title: '50+ 3rd Products (Open Text, GK POS, Blaze- On, Brava)', subtitle: 'with 100+ SAP CAL' },
    ];

    const CertificationCard = ({ icon, title, subtitle }) => (
        <div className="bg-[#14192EB2] p-4 rounded-2xl shadow-md flex items-center space-x-4">
            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-[#4F75FF] to-[#A100FF] flex items-center justify-center">
                <span className="text-white text-xl">{icon}</span>
            </div>
            <div className="flex-grow">
                <h3 className="text-sm md:text-base font-bold text-white">{title}</h3>
                <p className="text-xs md:text-sm text-gray-300">{subtitle}</p>
            </div>
        </div>
    );

    const Certifications = () => (
        <div className="py-12 md:py-24 flex flex-col items-center justify-center">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-white mb-8 md:mb-12 text-center">SAP Certified Provider</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 px-4 w-11/12 mx-auto">
                {certifications.map((cert, index) => (
                    <CertificationCard key={index} icon={cert.icon} title={cert.title} subtitle={cert.subtitle} />
                ))}
            </div>
        </div>
    );


    const FAQSection = () => {
        const [openIndex, setOpenIndex] = useState(null);

        const faqs = [
            {
                icon: <IoDocumentTextOutline />,
                title: "planning",
                answer: `A detailed, step-by-step roadmap is crafted, defining all the stages, potential risks and risk mitigation plans. This is accompanied with a project plan and resource requirements for implementation and ongoing management of SAP systems.
                `
            },
            {
                icon: <IoPersonOutline />,
                title: "Leadership",
                answer: "An expert SAP consultant with expertise in SAP product functions and how they can address your core business requirements would act as the team leader."
            },
            {
                icon: <IoResizeOutline />,
                title: "Sizing and Blueprinting",
                answer: "A complete TCO analysis including the assessment of costs due to downtime and maintenance are done."
            },
            {
                icon: <IoServerOutline />,
                title: "Installation",
                answer: "Installation guidelines and best practices we developed over numerous implementations are followed for the efficient installation of SAP software."
            },
            {
                icon: <IoSpeedometerOutline />,
                title: "Testing and Training",
                answer: "We perform repeated testing, including systems and stress testing. Before the go-live date, we train all users (from the technical support organization and end users) on the SAP systems."
            },
        ];

        const toggleFAQ = (index) => {
            setOpenIndex(openIndex === index ? null : index);
        };

        return (
            <div className="flex flex-col items-start justify-center p-4 space-y-2 2xl:max-w-7xl mx-auto">
                {faqs.map((faq, index) => (
                    <div key={index} className="w-full">
                        <div
                            className="flex items-center justify-between w-full bg-[#1E2235] rounded-lg p-4 cursor-pointer"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="flex items-center space-x-4">
                                <div className="text-[#A100FF]">{faq.icon}</div>
                                <span className="text-white">{faq.title}</span>
                            </div>
                            <motion.div
                                className="text-[#A100FF]"
                                animate={{ rotate: openIndex === index ? 180 : 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                {openIndex === index ? <FaMinus /> : <FaPlus />}
                            </motion.div>
                        </div>
                        <AnimatePresence>
                            {openIndex === index && (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="bg-[#14192E] overflow-hidden"
                                >
                                    <div className="p-4 mt-2 rounded-lg">
                                        <p className="text-white">{faq.answer}</p>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="bg-bg text-white">
            <Header
                title="DIAA CORP SAP Implementation services"
                description="Our full cycle implementation services help you design, deploy, and adopt SAP solutions faster, with higher availability and lower risk."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            <InformationSystemOverview />

            <div>

                <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center mb-4">
                        Why choose DIAA CORP's SAP Implementation Services?
                    </h2>
                    <h3 className="text-base sm:text-lg md:text-xl font-medium text-center mb-6 text-[#F1F1F1F1]">
                        End-To-End Solutions For Modernizing Your Core
                    </h3>
                    <p className="text-sm sm:text-base md:text-lg leading-7 text-[#F1F1F1F1]">
                        Being a SAP Implementation partner, we understand that a major part of digital transformation includes moving to an innovative digital core, which typically is an organization's ERP system. As SAP Implementation partners, we know that careful planning plays a prominent role and it includes choosing a partner that can support the initiative with advanced tools. A portfolio of services and a library of best practices and lessons learned is required to make the move easier.
                    </p>
                </div>

            </div>

            <div className="mx-auto mt-12 xl:mt-24">
                <CompleteSuiteServices />
            </div>

            <div>

                <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto mt-12 xl:mt-24">
                    <h3 className="text-base sm:text-lg md:text-xl font-medium text-center mb-6 text-[#F1F1F1F1]">
                        Our end-to-end SAP implementation services help enterprises in simplifying and accelerating the deployment of their SAP ERP solution. We use a systematic approach to kickstart your SAP implementation on-premises, in the cloud, or through a hybrid approach.
                    </h3>
                    <p className="text-sm sm:text-base md:text-lg leading-7 text-center text-[#F1F1F1F1]">
                        Through our SAP Implementation Services, we bring together industry best practices, proven implementation methodologies, and next-generation configuration tools. DIAA Corp accelerators can enable you to fast-track the deployment of your SAP solution. As SAP Implementation Partners, we provide smooth and easy migration to the SAP HANA database for speeding up your business with new real-time reporting capabilities,
                        and also implement modern Fiori web and mobile-based apps for your SAP applications.
                    </p>
                </div>

                <div className="mx-auto mt-12 xl:mt-24">
                    <GradientBoxes />
                </div>

                <div className="mx-auto mt-12 xl:mt-24">
                    <ProjectMethodology />
                </div>

                <div className="mx-auto mt-12 xl:mt-24">
                    <Certifications />
                </div>

                <div className="mx-auto mt-12 xl:mt-24">
                    <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto">
                        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center mb-4">
                            DIAA CORP SAP Implementation Service Features
                        </h2>
                        <h3 className="text-base sm:text-lg md:text-xl font-medium text-center mb-6 text-[#F1F1F1F1]">
                            DIAA Corp SAP Implementation services are defined to simplify, accelerate and make the entire implementation process failsafe. The services include detailed planning, testing, training, and more.
                        </h3>
                    </div>
                </div>

                <div className="xl:w-1/2 mx-auto mt-12 xl:mt-24">
                    <FAQSection />
                </div>

            </div>

            <div>
                <Footer />
            </div>
        </div >
    )
}

export default SAPImplementationServices