import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdMenu, IoMdClose } from 'react-icons/io';
import { CiGlobe } from 'react-icons/ci';
import { IoSearchOutline } from 'react-icons/io5';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import useScrollDirection from './useScrollDirection';
import AnimatedLogo from './AnimatedLogo';
import DIAA_NAME from '../../assets/DIAA_Trademark.png'; // Ensure you have a high-quality laptop image


const navItems = [
  {
    href: "/we-do",
    text: "We do",
    subItems: [
      {
        text: "SAP solutions",
        subItems: [
          "Rise with SAP",
          "Cloud Managed Services",
          "Enterprise Applications Managed Services",
          "SAP S4HANA",
          "SAP Ariba",
          "SAP CX",
          "SAP Business by Design",
          "SAP Migration factory",
          "SAP SuccessFactors",
          "SAP Consulting",
          "SAP Managed Services",
          "SAP Architectural Consulting",
          "SAP Implementation services",
          "SAP Application Management Services",
          "SAP Solution Manager",
          "SAP Hosting",
          "SAP Analytics Cloud"
        ].sort((a, b) => a === "Rise with SAP" ? -1 : b === "Rise with SAP" && 1)
      },
      {
        text: "Capabilities",
        subItems: [
          "Application Development",
          "Cloud",
          "Data and Artificial Intelligence",
          "Enterprise Platforms",
          "Learning",
          "Metaverse",
          "Sales and Commerce",
          "Strategic Managed Services",
          "Technology Transformation"
        ].sort((a, b) => (typeof a === 'string' && typeof b === 'string') ? a.localeCompare(b) : 0)
      },
      {
        text: "Industries",
        subItems: [
          "Communications and Media",
          "Education",
          "Energy",
          "Health",
          "High tech",
          "Industrial",
          "Public service",
          "Retail",
          "Software and platforms",
          "Travel",
          "Utilities"
        ].sort((a, b) => a.localeCompare(b))
      }
    ]
  },
  { href: "/we-think", text: "We think" },
  {
    href: "/we-are",
    text: "We are",
    subItems: [
      {
        text: "Our organization",
        subItems: [
          "Leaders",
          "Locations",
          "360° wallet report"
        ]
      },
      {
        text: "Media&Invester",
        subItems: [
          "Media relations",
          "Investors relation"
        ]
      }
    ]
  },
  // { href: "/we-hire", text: "We hire" },
  {
    href: "/we-hire",
    text: "We hire",
    subItems: [
      {
        text: "Find a job",
        subItems: [
          "Search all jobs",
          "Executive Leaders",
          "Experienced professionals",
          "Training and Development",
          "Work environment"
        ]
      },
      {
        text: "Explore jobs",
        subItems: [
          "Search jobs by areas of expertise",
          "Consulting jobs",
          "Corporate jobs",
          "Digital jobs",
          "Operation jobs",
          "Strategy jobs",
          "Technology jobs",
          "Industry X jobs"
        ]
      }
    ]
  },
];


const dropdownVariants = {
  hidden: { opacity: 0, y: -5 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.1 } }
};

const CustomScrollbar = ({ children, className }) => {
  const scrollContainerRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const scrollYProgress = useMotionValue(0);
  const scrollThumbHeight = useTransform(scrollYProgress, [0, 1], ['100%', '10%']);
  const scrollThumbPosition = useTransform(scrollYProgress, [0, 1], ['0%', '90%']);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const checkScrollable = () => {
        const isScrollable = scrollContainer.scrollHeight > scrollContainer.clientHeight;
        setShowScrollbar(isScrollable);
      };

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const progress = scrollTop / (scrollHeight - clientHeight);
        scrollYProgress.set(progress);
      };

      const preventPageScroll = (e) => {
        const { deltaY } = e;
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;

        if ((deltaY < 0 && isAtTop) || (deltaY > 0 && isAtBottom)) {
          e.preventDefault();
          e.stopPropagation();
        }
      };

      checkScrollable();
      scrollContainer.addEventListener('scroll', handleScroll);
      scrollContainer.addEventListener('wheel', preventPageScroll, { passive: false });
      window.addEventListener('resize', checkScrollable);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
        scrollContainer.removeEventListener('wheel', preventPageScroll);
        window.removeEventListener('resize', checkScrollable);
      };
    }
  }, [scrollYProgress]);

  return (
    <div className={`custom-scrollbar-container ${className}`}>
      <div ref={scrollContainerRef} className="custom-scrollbar-content">
        {children}
      </div>
      {showScrollbar && (
        <div className="custom-scrollbar-track">
          <motion.div 
            className="custom-scrollbar-thumb"
            style={{ 
              height: scrollThumbHeight,
              top: scrollThumbPosition
            }}
          />
        </div>
      )}
    </div>
  );
};

const DropdownMenu = React.memo(({ item, index, isMobile, toggleDropdown, activeDropdown, initialDropDown, closeSidebar }) => {
  const [activeSection, setActiveSection] = useState(item.subItems?.[0]?.text || "");
  const [activeMainSection, setActiveMainSection] = useState(item.subItems?.[0]?.text || "");
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    };
    scrollToTop();
  }, [location.pathname]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (!isMobile) {
      toggleDropdown(index);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (!isMobile) {
      toggleDropdown(null);
    }
  };

  const handleSectionClick = (sectionName) => {
    setActiveSection(sectionName);
    setActiveMainSection(sectionName);
  };

  const handleItemClick = () => {
    toggleDropdown(null);  // Close the dropdown
    setIsHovered(false)
    if (isMobile) {
      closeSidebar();
    }
  };

  const handleClick = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
      toggleDropdown(isOpen ? null : index);
    }
  };

  const isDropdownOpen = isMobile ? isOpen : (activeDropdown === index || isHovered);

  // Determine if scrollbar should be hidden for the current section
  const hideScrollbarForSection = activeMainSection === "Capabilities" || activeMainSection === "Industries";

  const renderMenuItem = (item, subIndex) => {
    if (typeof item === 'string') {
      const routePath = `/${item.toLowerCase().replace(/\s+/g, '-')}`;
      return (
        <Link key={`${item}-${subIndex}`} to={routePath} onClick={handleItemClick}>
          <motion.div
            className="px-4 py-3 text-sm md:text-md font-semibold text-white transition-colors duration-300 hover:bg-hover-gradient"
            whileHover={{ scale: 1.05, transition: { duration: 0.05 } }}
          >
            {item}
          </motion.div>
        </Link>
      );
    } else {
      return (
        <NestedDropdownItem
          key={`${item.text}-${subIndex}`}
          item={item}
          index={index}
          isMobile={isMobile}
          toggleDropdown={toggleDropdown}
          closeSidebar={closeSidebar}
          handleItemClick={handleItemClick}
        />
      );
    }
  };

  return (
    <div
      className={`relative py-2 md:py-6 cursor-pointer ${isMobile ? 'w-full' : 'group'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={() => {
          if (isMobile) {
            handleClick();
          } else if ('ontouchstart' in window) {
            isHovered ? handleMouseLeave() : handleMouseEnter();
          }
        }}
        className={`text-white md:mx-2 hover:text-gray-300 mobile:text-base text-sm flex items-center justify-between ${isMobile ? 'w-full py-2' : 'space-x-1'
          } transition-colors duration-300 ${isDropdownOpen && index !== 1 ? 'active-gradient-border' : ''
          }`}
      >
        <span>{item.text}</span>
        {item.subItems && (
          isMobile ? (isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />) :
            (isDropdownOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />)
        )}
      </button>
      <AnimatePresence>
        {isDropdownOpen && item.subItems && (
          <motion.div
            initial={initialDropDown}
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className={`${isMobile ? 'static w-full' : 'absolute -left-[20px] mt-2 w-80 mr-24'} bg-[#1A1D38] rounded-md shadow-lg z-10 ${item?.text === "We do" && 'w-96'}`}
          >
            <div className={`flex ${item?.text !== "We do" && 'space-x-6'}`}>
              {item.subItems.map((subItem, subIndex) => (
                <button
                  key={`${subItem.text}-${subIndex}`}
                  className={`px-4 py-4 text-sm font-semibold  ${activeSection === subItem.text ? 'text-[#a100ff] border-b-2 border-[#a100ff]' : 'text-[#686A86]'}`}
                  onMouseEnter={() => handleSectionClick(subItem.text)}
                >
                  {subItem.text}
                </button>
              ))}
            </div>
            <AnimatePresence mode="wait">
              {item.subItems.map((subItem, subIndex) => (
                activeMainSection === subItem.text && (
                  <motion.div
                    key={`${subItem.text}-${subIndex}`}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.05 }}
                  >
                    <CustomScrollbar>
                      {subItem.subItems.map((nestedItem, nestedIndex) => renderMenuItem(nestedItem, nestedIndex))}
                    </CustomScrollbar>
                  </motion.div>
                )
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

const NestedDropdownItem = React.memo(({ item, index, isMobile, toggleDropdown, closeSidebar, handleItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderNestedMenuItem = (subItem) => {
    if (typeof subItem === 'string') {
      const routePath = `/${subItem.toLowerCase().replace(/\s+/g, '-')}`;
      return (
        <Link to={routePath} onClick={handleItemClick}>
          <motion.div
            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
            className="px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-hover-gradient"
          >
            {subItem}
          </motion.div>
        </Link>
      );
    } else {
      return (
        <DeepNestedDropdownItem
          item={subItem}
          index={index}
          isMobile={isMobile}
          toggleDropdown={toggleDropdown}
          closeSidebar={closeSidebar}
          handleItemClick={handleItemClick}
        />
      );
    }
  };

  return (
    <div
      className={`relative  ${isMobile ? 'w-full' : 'group'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <motion.button
        onClick={() => isMobile && setIsHovered(!isHovered)}
        className="w-full px-4 py-2 text-sm text-white flex justify-between items-center transition-colors duration-300 hover:bg-hover-gradient"
      >
        {item.text}
        {isHovered ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
      </motion.button>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className={`${isMobile ? 'static w-full' : 'absolute left-full top-0 mt-0 w-64'} bg-[#1A1D38] rounded-md shadow-lg`}
          >
            {item.subItems.map((subItem, subIndex) => renderNestedMenuItem(subItem))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

const DeepNestedDropdownItem = React.memo(({ item, index, isMobile, toggleDropdown, closeSidebar, handleItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`relative ${isMobile ? 'w-full' : 'group'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <motion.button
        onClick={() => isMobile && setIsHovered(!isHovered)}
        whileHover={{ backgroundColor: '#2A2D48' }}
        className="w-full px-4 py-2 text-sm text-white flex justify-between items-center transition-colors duration-300 hover:bg-hover-gradient"
      >
        {item.text}
        {isHovered ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
      </motion.button>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className={`${isMobile ? 'static w-full' : 'absolute left-full top-0 mt-0 w-64'} bg-[#393F58] rounded-md shadow-lg`}
          >
            {item.subItems.sort((a, b) => a.localeCompare(b)).map((subItem, subIndex) => {
              const routePath = `/${subItem.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '')}`;
              return (
                <Link to={routePath} key={subIndex} onClick={handleItemClick}>
                  <motion.div
                    whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                    className="px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-hover-gradient"
                  >
                    {subItem}
                  </motion.div>
                </Link>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

const DESKTOP_BREAKPOINT = 1024;

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [initialDropDown] = useState("hidden");
  const [fadeIn, setFadeIn] = useState(false);
  const navRef = useRef(null);
  const { scrollDirection, scrollY } = useScrollDirection();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= DESKTOP_BREAKPOINT);

  const [showCountries, setShowCountries] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('India');

  const countries = ['India', 'KSA', 'US', 'UAE'];

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowCountries(false);
  };
  useEffect(() => {
    setFadeIn(true);
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= DESKTOP_BREAKPOINT);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = useCallback(() => setIsOpen(prev => !prev), []);

  const toggleDropdown = useCallback((index) => {
    setActiveDropdown(prev => prev === index ? null : index);
  }, []);

  const navbarStyle = useMemo(() => {
    const style = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    };

    if (isDesktop) {
      style.transition = 'transform 0.3s ease-in-out';
      style.transform = scrollDirection === 'down' && scrollY > 100 ? 'translateY(-100%)' : 'translateY(0)';
    }

    return style;
  }, [scrollDirection, scrollY, isDesktop]);
  const memoizedNavItems = useMemo(() => navItems.map((item, index) => (
    <DropdownMenu
      key={index}
      item={item}
      index={index}
      isMobile={false}
      toggleDropdown={toggleDropdown}
      activeDropdown={activeDropdown}
      initialDropDown={initialDropDown}
      closeSidebar={toggleSidebar}
    />
  )), [toggleDropdown, activeDropdown, initialDropDown, toggleSidebar]);

  const memoizedMobileNavItems = useMemo(() => navItems.map((item, index) => (
    <li key={index}>
      <DropdownMenu
        item={item}
        index={index}
        isMobile={true}
        toggleDropdown={toggleDropdown}
        activeDropdown={activeDropdown}
        initialDropDown={initialDropDown}
        closeSidebar={toggleSidebar}
      />
    </li>
  )), [toggleDropdown, activeDropdown, initialDropDown, toggleSidebar]);


  return (
    <nav ref={navRef} className={`bg-bg p-2 lg:p-0 ${fadeIn ? 'fade-in' : ''}`} style={navbarStyle}>
      <div className="md:px-2 xl:w-11/12 mx-auto flex justify-between items-center">
        <Link to={"/"} className='text-white mt-[1px]'>
          <AnimatedLogo />
        </Link>

        <div className="hidden lg:flex flex-grow justify-center space-x-4 lg:space-x-8 mr-28">
          {memoizedNavItems}
        </div>


        <div className="flex items-center space-x-4">
          <IoSearchOutline className="h-5 w-5 text-white" />
          <div
            className="flex items-center relative hover:cursor-default lg:w-24"
            onMouseEnter={() => setShowCountries(true)}
            onMouseLeave={() => setShowCountries(false)}
          >
            <CiGlobe className="h-5 w-5 text-white" />
            <motion.span
              key={selectedCountry}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
              className="ml-2 text-white text-sm hidden lg:inline"
            >
              {selectedCountry}
            </motion.span>
            <IoMdArrowDropdown className="text-white hidden lg:inline" />

            <AnimatePresence>
              {showCountries && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className="absolute top-full left-0 mt-1 rounded-md shadow-lg overflow-hidden"
                  style={{ background: "rgba(7, 7, 39, 1)", minWidth: '120px' }}
                >
                  <ul className="py-1">
                    {countries.map((country, index) => (
                      <motion.li
                        key={country}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.2, delay: index * 0.05 }}
                        className="px-4 py-2 hover:bg-[rgba(255,255,255,0.1)] cursor-pointer text-white text-sm"
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country}
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <button onClick={toggleSidebar} className="text-white lg:hidden">
            <IoMdMenu className="h-6 w-6" />
          </button>

        </div>

      </div>

      {/* Mobile Sidebar */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden"
          >
            <div className="bg-[#1A1D38] w-full h-full p-4 overflow-y-auto">
              <div className="flex justify-between items-center mb-8">
                <h4 className='text-white text-2xl'>
                  <img src={DIAA_NAME} alt="DIAA CORP" className="w-1/2" />

                </h4>
                <button onClick={toggleSidebar} className="text-white">
                  <IoMdClose className="h-6 w-6" />
                </button>
              </div>
              <ul className="space-y-4">
                {memoizedMobileNavItems}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default React.memo(Nav);