import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import image1 from '../assets/Risewithsap/risewithsap-1.png';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import Footer from '../components/Footer';
import { StatsCard } from '../components/customHooks/StatsCard';
import LocationMap from '../components/SAP/Map';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const AnimatedBackground = () => {
  const lines = 30; // Number of wave lines
  const baseColor = 'a100ff'; // Base color in hex

  return (
    <svg className="absolute inset-0 w-full h-[600px] mobile:h-[600px]" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <defs>
        {[...Array(lines)].map((_, i) => (
          <linearGradient key={`gradient-${i}`} id={`gradient-${i}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={`#${baseColor}`} stopOpacity={(i + 1) / lines} />
            <stop offset="100%" stopColor="#a100ff" stopOpacity={(i + 1) / lines} />
          </linearGradient>
        ))}
      </defs>
      {[...Array(lines)].map((_, i) => (
        <motion.path
          key={`wave-${i}`}
          d={`M0 ${150 + i * 40} Q250 ${100 + i * 40} 500 ${150 + i * 40} T1000 ${150 + i * 40} T1500 ${150 + i * 40} T2000 ${150 + i * 40} T2500 ${150 + i * 40} V600 H0 Z`}
          fill={`url(#gradient-${i})`}
          initial={{ y: 0 }}
          animate={{
            y: [0, -20, 0],
            x: [0, i % 2 === 0 ? 20 : -20, 0]
          }}
          transition={{
            repeat: Infinity,
            duration: 3 + i,
            ease: "easeInOut",
          }}
        />
      ))}
    </svg>
  );
};

// const StatsCard = ({ value, label, customStlye }) => (
//   <div className={`bg-[#070727] p-6 rounded-lg ${customStlye}`}>
//     <h3 className="text-4xl font-bold mb-2">{value}</h3>
//     <p className="text-sm text-gray-400">{label}</p>
//   </div>
// );




const RiseWithSap = () => {
  const [fadeIn, setFadeIn] = useState(true);
  useDocumentTitle('Rise with SAP');


  // useEffect(() => {
  //   setFadeIn(true);
  // }, []);


  function SapRiseComponent() {
    const services = [
      { text: "Business Process Automation", position: "top-[5%] left-1/2 -translate-x-1/2" },
      { text: "SAP Implementation Services (Greenfield / Brownfield / Bluefield)", position: "top-[20%] md:left-[-5%] lg:left-[-8%]" },
      { text: "Healthcare | Pharma | Retail | Manufacturing | Energy & Utilities", position: "top-[20%] md:right-[-5%] lg:right-[-9%]" },
      { text: "Functional Support (AMS Services)", position: "top-1/2 md:left-[-4%] lg:left-[-7%] -translate-y-1/2" },
      { text: "SAP Technology Factory (Upgrades, Conversions, Migrations etc.)", position: "top-1/2 md:right-[-5%] lg:right-[-9%] -translate-y-1/2" },
      { text: "SAP Basis Administration", position: "bottom-[25%] md:left-[2%] lg:left-[0%]" },
      { text: "Database Management (HANA, Sybase, MS SQL)", position: "bottom-[23%] md:right-[-5%] lg:right-[-9%]" },
      { text: "Infrastructure, Network, Security and Operating System Management", position: "bottom-[0%] md:left-[3%] lg:left-[6%]" },
      { text: "DIAA Corp SAP Cloud as per RISE Reference Framework", position: "bottom-[0%] md:right-[2%] lg:right-[5%]" },
    ];

    return (
      <div className="text-white p-4 md:p-8 my-10 flex items-center justify-center bg-bg">
        <div className="w-full max-w-6xl">
          {/* Desktop view */}
          <div className="hidden 2xl:block relative aspect-[16/9]">
            {services.map((service, index) => (
              <div
                key={index}
                className={`absolute ${service.position} max-w-[30%] lg:max-w-[35%] bg-[#1A1F31] rounded-full p-2 md:p-3 text-center flex items-center justify-center`}
              >
                <span className="mx-2 md:mx-2 xl:mx-6 text-xs md:text-sm lg:text-base xl:text-lg">{service.text}</span>
              </div>
            ))}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30%] aspect-square">
              <div className="absolute inset-0 bg-[#1c1e33] opacity-30 rounded-full"></div>
              <div className="absolute inset-[15%] bg-[#1c1e33] opacity-50 rounded-full"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-xl md:text-2xl font-bold text-white">Sap Rise</div>
              </div>
            </div>
          </div>

          {/* Mobile and iPad view */}
          <div className="2xl:hidden flex flex-col items-center space-y-4">
            <div className="bg-[#1A1F31] rounded-full p-6 mb-6">
              <div className="text-2xl font-bold text-white">Sap Rise</div>
            </div>
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-[#1A1F31] rounded-full p-3 text-center w-full"
              >
                <span className="text-sm md:text-base">{service.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }



  const EndToEndRiseSection = () => (
    <div className="mx-auto px-4 md:w-11/12 lg:w-3/4">
      <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-8">
        The only End-to-end RISE with SAP Services partner
      </h2>
      <p className="text-center text-gray-300 mb-12 mx-auto max-w-3xl">
        DIAA Corp has expertise in handling SAP workloads and S4HANA Transformations. We work as a One Stop shop for all
        SAP services offered at a Single SLA at App login layer. DIAA Corp is the largest premier supplier of SAP RISE and has
        proven expertise in handling mission-critical, sophisticated, and vast SAP environments for more than 700
        customers, worldwide.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-8">
        <StatsCard customStyle="md:pb-16" value="700+" label="Customers" />
        <StatsCard value="2990+" label="SAP Instances" />
        <StatsCard value="7000+" label="SAP Projects" />
        <StatsCard value="1000+" label="SAP Consultants" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <StatsCard value="52" label="Global Infrastructure" />
        <StatsCard value="99%" label="High Availability" />
        <StatsCard value="50+" label="Countries Served" />
      </div>
    </div>
  );


 




  const DropdownSection = ({ number, title, content, isOpen, onToggle }) => {
    return (
      <div className="mb-4 flex items-start">
        <span className="text-4xl md:text-5xl lg:text-7xl font-bold mr-4 bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent ">{number}</span>
        <div className="flex-1">
          <button
            className="w-full flex items-center justify-between p-4 bg-[#1A1F31] rounded-lg hover:bg-[rgba(255,255,255,0.1)] transition-colors duration-300"
            onClick={onToggle}
          >
            <h2 className="text-white text-base md:text-lg lg:text-2xl md:py-4">{title}</h2>
            <svg
              className={`w-6 h-6 text-white transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <div className="p-4 bg-[rgba(255,255,255,0.03)] text-sm mt-2 rounded-lg text-white">
                  {content}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };

  const DropdownList = () => {
    const [openSection, setOpenSection] = useState(null);

    const sections = [
      {
        number: '1',
        title: 'Your SAP. Your choice of cloud and Geography',
        content: (
          <div>
            <p className="mb-4">If you are a global enterprise with business activities and transactions dispersed over a vast geographic area then your search for the right RISE strategic partner ends here. As a globally recognized Managed Services provider with an ever-expanding presence across multi-availability zones and enterprise-level support services for the leading hyperscalers, we offer proven expertise, experience, and resources to support the RISE delivery model and help you move your SAP.</p>
            <h3 className="text-xl font-semibold my-8 text-center ">Why DIAA CORP</h3>
            <div className="grid grid-cols-1  md:grid-cols-3 lg:gap-12 lg:mx-12 lg:px-4 ">
              {[
                { icon: "🏢", text: "Designated SAP RISE Center of Excellence" },
                { icon: "🔧", text: "Intelligent Managed Services powered by AI/ML" },
                { icon: "📊", text: "Industry specific compliance services like HIPAA, GxP, MFSA, FedrAMP, etc." },
                { icon: "⚙️", text: "Optimized architectures for HANA, ORACLE and SQL among others" },
                { icon: "🌎", text: "Available in 30 countries with multi-language cloud offerings" },
                { icon: "🚀", text: "Powering 80 out of Fortune 500 in their SAP transformation" },
              ].map((item, index) => (
                <div key={index} className="flex items-center border border-theme p-3 rounded-lg lg:py-12 mx-auto max-w-sm mobile:mb-4">
                  <span className="text-2xl mr-2">{item.icon}</span>
                  <span className="text-sm">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        ),
      },
      {
        number: '2', title: 'Insure Your SAP from the Unknown & Uncontrollable',
        content: 'As you plan your move to SAP S/4HANA Cloud, Private Edition, that is essentially a privately managed cloud environment, its impact on regulatory compliance and regulations becomes a key concern to ensure every aspect of your new landscape is secure. With DIAA CORP, your every compliance need is premet as cybersecurity tops the priority list for our client deliveries. Running mission critical SAP applications demand an eye on compliance, especially for segments such as public sector, utilities and healthcare. We recognized these specific customer needs ahead of the curve and have invested in multiple regulations and compliance related certifications across the globe.'
      },
      {
        number: '3', title: 'Highest SLA in the industry for SAP RISE delivered',
        content: "SAP's contractual SLA commitments to its customers is 99.5% (standard) and 99.7% (on-demand) for Production systems. DIAA Corp continues to excel on this key qualification criteria by delivering cloud services at a single SLA of 99.994% at the application level. With investments in proprietary and market leading cloud automation tools, we effectively control day-to-day operations in a highly automated approach, hence reducing the human intervention on delivering SAP managed services and thereby reducing unforeseen errors. Check out DIAA Corp's award-winning Self healing operations platform (SHOP) here"
      },
      {
        number: '4', title: 'Uninterrupted Business Continuity and Disaster Recovery Solutions for SAP RISE Guaranteed',
        content: 'Our business continuity solutions add additional dimension and flexibility for customers opting for SAP RISE solutions. With adherence to all specific requirements defined by SAP for Disaster Recovery Solutions, DIAA CORP offers both Long Distance DR and Short Distance DR. In addition, DIAA CORP dedicated DR CoE offers Disaster Recovery as a service (DRaaS) that fulfills any custom business continuity requirements customers may have.'
      },
      {
        number: '5', title: 'Enterprise-grade Managed Services across key business applications',
        content: "SAP RISE offering covers a highly comprehensive set of services catering to the unique demands of SAP solutions. DIAA CORP, a native cloud managed services company extends the principles defined by SAP to other enterprise applications as well, ensuring the highest level of SLA commitments to the customer's entire IT landscape which could be beyond SAP. Some of the other key enterprise applications include OpenText, Vistex, MSG, Microfocus, HCL, Utopia, Greenlight, Digital Route, KNOA and many others."
      },
      {
        number: '6', title: 'Unmatched Cloud Professional Services – Implementations and Digital Transformations',
        content: "Customers adopting SAP RISE need a system integrator for requirements beyond cloud deployment. DIAA CORP takes cognizance of the same and continues to build its capability across key industries and all lines of business of SAP e.g. S/4HANA, SAP Business Technology Platform, Supply Chain, Finance, procurement, Human Resources, among others .DIAA CORP's proprietary offering SAP Switch2Cloud offers SAP customers a complete transformation package, encompassing Cloud Infrastructure and Managed Services, SAP Implementation Services, and SAP Application Management Services. This approach allows customers to reduce multiple touchpoints with vendors and at the same time ensures the lowest possible TCO in the ecosystem with the highest quality standards."
      },
    ];

    const handleToggle = (number) => {
      setOpenSection(openSection === number ? null : number);
    };

    return (
      <div className="mobile:max-w-7xl md:w-3/4 mx-auto p-4">
        {sections.map((section) => (
          <DropdownSection
            key={section.number}
            {...section}
            isOpen={openSection === section.number}
            onToggle={() => handleToggle(section.number)}
          />
        ))}
      </div>
    );
  };


  const InsightsSection = () => {
    const insights = [
      {
        title: "What is RISE With SAP? A Comprehensive Guide for IT Leaders",
        readMore: "#"
      },
      {
        title: "The 7 Key Steps in Your Journey to SAP S/4HANA",
        readMore: "#"
      },
      {
        title: "How to Select The Best SAP Partner: Dynamics That Matter",
        readMore: "#"
      }
    ];

    const scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    };

    return (
      <div className="text-white py-8 sm:py-12 md:py-16">
        <div className="mx-auto ">
          <div className="flex flex-col justify-center items-center text-center mb-12 bg-[#14192E] py-12 px-4 rounded-lg">
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-8">Ask us how we can help you to RISE</h2>
            <motion.button
              className="bg-custom-gradient text-white font-normal py-2 px-6 sm:px-8 md:px-12 rounded-full text-sm sm:text-base"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={scrollToBottom}
            >
              Talk to us
            </motion.button>
          </div>

          <div className="mb-12 text-center">
            <h1 className='text-xl sm:text-2xl md:text-3xl font-bold mb-2'>Latest Thoughts & Insights on RISE with SAP</h1>
            <h3 className="text-base sm:text-lg font-light mb-2 opacity-75">RISE with SAP</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto max-w-6xl px-4">
            {insights.map((insight, index) => (
              <div key={index} className="bg-[#14192E] p-6 rounded-lg flex flex-col justify-between h-full">
                <h4 className="text-base sm:text-lg font-semibold mb-8">{insight.title}</h4>
                <div className="text-right">
                  <a
                    href={insight.readMore}
                    className="text-theme hover:text-blue-400 transition duration-300 inline-block text-sm sm:text-base"
                  >
                    Read more →
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className={`bg-bg text-white ${fadeIn ? 'fade-in' : ''}`}>
      {/* Hero Section */}
      <div className="min-h-[400px] relative overflow-hidden flex flex-col items-center justify-center text-center px-4 py-12">
        <AnimatedBackground />
        <div className="z-1 relative">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-4">Rise with SAP</h1>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-300 font-normal text-center mb-8">Now available on DIAA CORP</p>
          <motion.button
            className="bg-custom-gradient text-white font-bold py-2 px-4 sm:py-3 sm:px-6 md:px-12 rounded-full text-sm sm:text-base"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Explore more
          </motion.button>
        </div>
      </div>

      {/* One Offer. One Contract. Section */}
      <div className="container mx-auto px-4 py-8 sm:py-12 md:py-16">
        <div className="rounded-lg p-4 md:p-8">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-6">One Offer. One Contract.</h2>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="lg:w-2/3">
              <p className="mb-6 text-sm sm:text-base md:text-lg">
                As SAP's strategic partner, DIAA CORP offers an all-in-one
                single-subscription contract to create a unified experience
                and a seamless business transformation. Enterprises are in
                need of trusted strategic partners who will help them
                accelerate and unlock business value and profit with SAP, and
                DIAA CORP is here to deliver.
              </p>
              <p className="text-sm sm:text-base md:text-lg">
                DIAA CORP takes its strategic partnership with SAP one step
                ahead by launching its business transformation service
                supporting SAP's latest offering, RISE with SAP, now available
                on DIAA CORP hyper-scale cloud. With this announcement,
                we deliver a 99.95% availability of SAP Hana database, a 99.9%
                system level uptime (SLA) of SAP, and a 99.9% on customer
                demand. At the core of RISE with SAP is SAP S/4HANA Cloud,
                SAP's SaaS ERP solution, an as-a-service subscription
                model designed to help organizations convert existing SAP in an
                agile, flexible way.
              </p>
            </div>
            <div className="lg:w-1/3">
              <img
                src={image1}
                alt="SAP Services"
                className="w-full h-auto rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>



      <div className='bg-[#14192E] py-8 sm:py-12 md:py-16'>
        <EndToEndRiseSection />
      </div>

      <div className="py-8 sm:py-12 md:py-16">
        <SapRiseComponent />
      </div>

      <div className="py-8 sm:py-12 md:py-16">
        <LocationMap />
      </div>

      <div className="py-8 sm:py-12 md:py-16">
        <DropdownList />
      </div>

      <div className="py-8 sm:py-12 md:py-16">
        <InsightsSection />
      </div>

      <Footer />

    </div>
  );
};

export default RiseWithSap;