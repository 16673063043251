import React from 'react';
import DIAAPng from "../../assets/DIAA_Trademark.png";

const AnimatedLogo = () => {
  return (
    <div className="flex items-center space-x-1.5 lg:pl-2">
      <img 
        src={DIAAPng} 
        alt="DIAA" 
        className="h-10 sm:h-10 md:h-12 xl:h-14 object-contain" 
      />
    </div>
  );
};

export default AnimatedLogo;