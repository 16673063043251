import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/StrategicManagedServices_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Strategic_img1 from '../../assets/Capabilities/Strategic_img1.png'
import Strategic_img2 from '../../assets/Capabilities/Strategic_img2.png'
import Strategic_img3 from '../../assets/Capabilities/Strategic_img3.png'
import Strategic_img4 from '../../assets/Capabilities/Strategic_img4.png'
import Strategic_img5 from '../../assets/Capabilities/Strategic_img5.png'
import Strategic_img6 from '../../assets/Capabilities/Strategic_img6.png'
import Strategic_img7 from '../../assets/Capabilities/Strategic_img7.png'
import Strategic_img8 from '../../assets/Capabilities/Strategic_img8.png'
import Strategic_img9 from '../../assets/Capabilities/Strategic_img9.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
function CloudServices() {

    useDocumentTitle("Strategic Managed Services");

    const SalesAndCommerceStatsComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-8 font-sans flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/3 sm:pr-8 mb-6 sm:mb-0 flex items-center justify-center">
                    <img
                        src={Strategic_img1}
                        alt="Why managed services illustration"
                        className="w-3/4 sm:w-full max-w-xs sm:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full sm:w-2/3">
                    <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 md:mb-12 lg:mb-16 text-center sm:text-left">Why managed services?</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="1.4x"
                            description="higher operating margins"
                        />
                        <StatCard
                            value="2.2x"
                            description="greater total shareholder returns (TSR)"
                        />
                        <StatCard
                            value="42%"
                            description="improvement in go-to-market and product innovation"
                        />
                        <StatCard
                            value="34%"
                            description="better at reducing energy consumption and greenhouse gas emissions"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithStrategicManagedServices = () => {
        const cards = [
            {
                title: 'Business Process Services',
                description: 'Bringing intelligent business operations to innovate and run core processes',
                hasButton: true,
                image: 'path/to/business-process-services-image.png'
            },
            {
                title: 'Global Capability Centers',
                description: 'Global capability centers help organizations access talent and innovation to unlock value',
                hasButton: true,
                image: 'path/to/global-capability-centers-image.png'
            },
            {
                title: 'Managed Data, AI and Automation',
                description: 'Realize real-time insights of enterprise data through generative AI and automation',
                hasButton: true,
                image: 'path/to/managed-data-ai-automation-image.png'
            },
            {
                title: 'Cybersecurity Managed Services',
                description: 'Feel confident defending and protecting against threats in every corner of your organization',
                hasButton: true,
                image: 'path/to/cybersecurity-managed-services-image.png'
            },
            {
                title: 'Managed Technology Transformation',
                description: 'Develop a roadmap that delivers the necessary operational and technological changes',
                hasButton: true,
                image: 'path/to/managed-technology-transformation-image.png'
            },
            {
                title: 'Managed Infrastructure and Applications',
                description: 'Modernize and re-platform faster to achieve goals',
                hasButton: true,
                image: 'path/to/managed-infrastructure-applications-image.png'
            }
        ];

        return <ReinventGrid title="Reinvent with strategic managed services" cards={cards} />;
    };
    const slides = [
        {
            title: "Reinventing Enterprise Operations",
            image: Strategic_img3,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "The CHRO as a growth executive",
            image: Strategic_img4,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "The tech-powered operating model",
            image: Strategic_img5,
            gradient: "from-pink-400 to-pink-600"
        }
    ];


    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending with cloud?" slides={slides} />;
    };



    const GlobalRecognition = () => {
        const awards = [
            {
                title: "DIAA CORP Named Overall Leader in the Group Consulting Services Peak",
                image: Strategic_img6,
                alt: "DIAA CORP Overall Leader"
            },
            {
                title: "A Leader in Mortgage Operations",
                image: Strategic_img7,
                alt: "Leader in Mortgage Operations"
            },
            {
                title: "A Leader in life science sales and marketing",
                image: Strategic_img8,
                alt: "Leader in Life Science Sales and Marketing"
            },
            {
                title: "A Leader in IDC MarketScape for worldwide cloud",
                image: Strategic_img9,
                alt: "Leader in IDC MarketScape for Worldwide Cloud"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    const segments = [
        { title: 'Supply chain', expanded: false },
        { title: 'Finance operations', expanded: true, description: 'See all the value your business is creating for today and tomorrow with instant data and insights. Make compliance secure and efficient.' },
        { title: 'Sourcing & procurement', expanded: false },
        { title: 'Digital engineering & manufacturing', expanded: false },
        { title: 'Talent and HR', expanded: false },
        { title: 'Marketing, sales & customer service', expanded: false },
        { title: 'Banking & capital markets', expanded: false },
        { title: 'Insurance', expanded: false },
        { title: 'Health & life sciences', expanded: false },
        { title: 'Telecommunications & network', expanded: false },
        { title: 'Compliance', expanded: false },
        { title: 'Legal', expanded: false },
        { title: 'Captive and retained', expanded: false },
        { title: 'Operations activation services', expanded: false },
        { title: 'Trust & safety', expanded: false },
      ];
      
      const SegmentsWeSupport = () => {
        return (
          <div className="text-white p-4 sm:p-8">
            <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center">Segments we support</h2>
            <div className="space-y-2">
              {segments.map((segment, index) => (
                <div key={index} className={`bg-[#14192E] rounded-xl overflow-hidden ${segment.expanded ? 'p-3 sm:p-4' : 'p-2'}`}>
                  <h3 className={`font-semibold text-center py-2 ${segment.expanded ? 'text-purple-500' : 'text-white'} text-sm sm:text-base`}>
                    {segment.title}
                  </h3>
                  {segment.expanded && segment.description && (
                    <div className="mt-2">
                      <div className="flex flex-col sm:flex-row items-center sm:items-start">
                        <div className="flex-shrink-0 mb-3 sm:mb-0 sm:mr-4">
                          <img src={Strategic_img2} alt={segment.title} className="w-full sm:w-32 h-auto sm:h-24 object-cover rounded-lg" />
                        </div>
                        <p className="text-xs sm:text-sm text-center sm:text-left">{segment.description}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      };



    return (
        <div className='bg-bg text-white '>
            <Header
                title="Managed services for changing more, faster"
                description="As organizations strive to optimize cost and accelerate growth, strategic managed services can help to better prepare them to reinvent operations and drive holistic value at scale"
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <SalesAndCommerceStatsComponent />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithStrategicManagedServices />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <SegmentsWeSupport />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>

    )
}

export default CloudServices