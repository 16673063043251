import React, { useEffect, useState } from 'react'
import image1 from '../assets/SapS4hana/SAP-SuccessFactors.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import imageCover from '../assets/SapS4hana/SapSuccessCover.png'; 
import { useDocumentTitle } from '../utils/useDocumentTitle';


const SapSuccessFactors = () => {
    const headerData = {
        title: "What Is SAP SuccessFactors",
        description: "SAP SuccessFactors, Human Experience Management (HXM) Suite is a cloud-based solution designed to meet the employee’s needs, work, and motivation. It enables you to manage diverse HR functions, such as core and payroll, Employee Experience Management, Talent Management, and Analytics."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP SuccessFactors Industries",
            items: ['Energy & Natural Resources', 'Government Sector', 'Wholesale & Distribution', 'Retail & Restaurants', 'EC&O', 'Professional Service', 'Manufacturing']
        },
        {
            title: "SAP SuccessFactors Availability",
            items: [ 'Cloud'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "SAP SuccessFactors Employees",
            items: ['Enterprise SMB'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1],
        description: "Explore the features and functionalities of SAP Successfactor through an gallery."
    };

    const benefitsData = {
        title: "",
        description: "",
        benefits: [
            {
                icon: '⏱️',
                title: '',
                description: 'A single global core HR platform with consistent and standardized HR processes focused on the entire workforce.'
            },
            {
                icon: '📊',
                title: '',
                description: 'True self-service to support automation, improve data quality, and elevate HR service delivery with real-time processes.'
            },
            {
                icon: '📄',
                title: '',
                description: 'Individualized, relevant, and meaningful talent experiences.'
            },
            {
                icon: '📈',
                title: '',
                description: 'Guidance and recommendations throughout the employee journey to empower employees each step of the way.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP SuccessFactors Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover} />
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={true} />
            <PaymentCards title={`SAP SuccessFactors Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapSuccessFactors;