import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter
import Home from './Home';
import Navbar from '../components/navbar/Navbar';
import RiseWithSap from './RiseWithSap';
import ComingSoon from './ComingSoon';
import SapS4hana from './SapS4hana';
import SapAriba from './SapAriba';
import SapBusinessBydesign from './SapBusinessBydesign';
import SapCx from './SapCx';
import SapSuccessFactors from './SapSuccessFactors';
import SAPMigrationFactory from './SAPMigrationFactory';
import CloudManagedServices from './CloudManagedServices';
import EnterpriseApplications from './EnterpriseApplications';
import SapConsulting from './SapConsulting';
import SapManagedServices from './SapManagedServices';
import ApplicationDevelopmentPage from './ApplicationDev';
import SAPArchitecturalConsulting from './SAPArchitecturalConsulting';
import SAPImplementationServices from './SAPImplementationServices';
import SAPApplicationManagementServices from './SAPApplicationManagementServices';
import SAPSolutionManager from './SAPSolutionManager';
import SAPHosting from './SAPHosting';
import SAPAnalyticsCloud from './SAPAnalyticsCloud';
import CloudServices from './Capabilities/CloudServices';


import CommunicationsAndMedia from './Industries/CommunicationsAndMedia';
import DataAndArtificialIntelligence from './Capabilities/DataAndArtificialIntelligence';
import EnterprisePlatforms from './Capabilities/EnterprisePlatforms';
import Learning from './Capabilities/Learning';
import Metaverse from './Capabilities/Metaverse';
import SalesAndCommerce from './Capabilities/SalesAndCommerce';
import StrategicManagedServices from './Capabilities/StrategicManagedServices';
import TechnologyTransformation from './Capabilities/TechnologyTransformation';
import Energy from './Industries/Energy';

function AppRouter() {
    return (
        <Router> {/* Wrap Routes in Router */}
            <div style={{ paddingTop: '64px' }}>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/rise-with-sap" element={<RiseWithSap />} />
                    <Route path="/sap-s4hana" element={<SapS4hana />} />
                    <Route path="/sap-ariba" element={<SapAriba />} />
                    <Route path="/sap-business-by-design" element={<SapBusinessBydesign />} />
                    <Route path="/sap-cx" element={<SapCx />} />
                    <Route path="/sap-successfactors" element={<SapSuccessFactors />} />
                    <Route path="/sap-migration-factory" element={<SAPMigrationFactory />} />
                    <Route path="/cloud-managed-services" element={<CloudManagedServices />} />
                    <Route path="/enterprise-applications-managed-services" element={<EnterpriseApplications />} />
                    <Route path="/sap-consulting" element={<SapConsulting />} />
                    <Route path="/sap-managed-services" element={<SapManagedServices />} />
                    <Route path="/application-development" element={<ApplicationDevelopmentPage />} />
                    <Route path="/sap-architectural-consulting" element={<SAPArchitecturalConsulting />} />
                    <Route path="/sap-implementation-services" element={<SAPImplementationServices />} />
                    <Route path="/sap-application-management-services" element={<SAPApplicationManagementServices />} />
                    <Route path="/sap-solution-manager" element={<SAPSolutionManager />} />
                    <Route path="/sap-hosting" element={<SAPHosting />} />
                    <Route path="/sap-analytics-cloud" element={<SAPAnalyticsCloud />} />
                    <Route path="/cloud" element={<CloudServices />} />
                    <Route path="/application-development" element={<ApplicationDevelopmentPage />} />
                    <Route path="/cloud" element={<CloudServices />} />
                    <Route path="/communications-and-media" element={<CommunicationsAndMedia />} />
                    <Route path="/data-and-artificial-intelligence" element={<DataAndArtificialIntelligence />} />
                    <Route path="/enterprise-platforms" element={<EnterprisePlatforms />} />
                    <Route path="/learning" element={<Learning />} />
                    <Route path="/metaverse" element={<Metaverse />} />
                    <Route path="/sales-and-commerce" element={<SalesAndCommerce />} />
                    <Route path="/strategic-managed-services" element={<StrategicManagedServices />} />
                    <Route path="/technology-transformation" element={<TechnologyTransformation />} />
                    <Route path="/energy" element={<Energy />} />
                    <Route path="*" element={<ComingSoon />} />
                    {/* Add more routes here */}
                </Routes>
            </div>
        </Router>
    );
}

export default AppRouter;
