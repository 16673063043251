import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/SapSolutionManager_cover.png'
import SapSolutionManager_img1 from '../assets/SAP/SapSolutionManager_img1.png'
import SapSolutionManager_img2 from '../assets/SAP/SapSolutionManager_img2.png'
import { ChevronRight } from 'lucide-react';
import { ContactContent } from '../components/resuable/SapComponents';
import { FaArrowCircleUp, FaChartBar, FaClipboardCheck, FaCloud, FaCogs, FaGraduationCap, FaHeadset, FaLink, FaSearch, FaWrench } from 'react-icons/fa';
import { IoCloudOutline } from 'react-icons/io5';
import { MdCloudDone } from 'react-icons/md';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SAPSolutionManager() {

    useDocumentTitle("SAP Solution Manager");
    

    const SAPSolutionManagerCard = () => {
        return (
            <div className="text-white p-4 sm:p-8 rounded-lg flex flex-col md:flex-row items-center">
                <div className="md:w-2/3 pr-0 md:pr-8">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-4">
                        Streamline And Modernize Your Processes With SAP Solution Manager
                    </h2>
                    <p className="text-xs sm:text-sm mb-6">
                        SAP Solution Manager helps streamline and modernize business processes,
                        discover improvements, as well as take care of end-to-end application lifecycle
                        management. It is hence a potent tool for increasing operational efficiency and
                        reducing the effort required to manage centralized SAP and non-SAP systems
                        running on-premise, hybrid, or on the cloud, all within SAP customers' existing
                        license agreements -- no additional costs!
                    </p>
                </div>
                <div className="md:w-1/3 mt-6 md:mt-0">
                    <img
                        src={SapSolutionManager_img1}
                        alt="SAP Solution Manager"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
            </div>
        );
    };

    const Card = ({ title, content }) => (
        <div className="relative p-[1px] rounded-2xl overflow-hidden md:w-1/2 xl:w-11/12 mx-auto">
            <div className="absolute inset-0 bg-custom-gradient"></div>
            <div className="bg-bg p-4 sm:p-6 rounded-2xl h-full relative z-10 px-4 sm:px-8 xl:py-8 sm:py-12">
                <h3 className="text-lg sm:text-xl font-semibold mb-4 text-white text-center">{title}</h3>
                <p className="text-xs sm:text-sm text-gray-300">{content}</p>
            </div>
        </div>
    );

    const SAPSolutionManagerCards = () => {
        const cards = [
            {
                title: "SAP Solution Manager Roadmap Assessment",
                content: "We conduct an initial assessment workshop, study your existing IT process, and identify functional areas that would benefit your organization. We then come with a customized roadmap to use SAP Solution Manager to simplify and optimize your SAP projects. We also provide an exclusive value report that includes expected benefits, implementation, or upgrade efforts in addition to the roadmap."
            },
            {
                title: "Application Lifecycle Assessment",
                content: "In partnership with your organization, we analyze your existing IT processes and tools and interview your key stakeholders to help you measure the maturity levels of the existing Application Lifecycle Management (ALM) processes. We then identify and prioritize steps to optimize your ALM processes. We provide a report with all our findings, recommendations, and cost estimates for the initial implementation."
            },
            {
                title: "Application Management Support",
                content: "Even after your Projects Go Live, our team will maintain SAP Solution Manager and free your teams to focus on other projects and operations. We provide multiple levels of support and ensure high availability of your IT processes. We also offer services for all SAP Solution Manager Functions and Modules ranging from solution documentation to test management, from monitoring to reporting, and from incident to change management, etc."
            },
            {
                title: "Integration With Other Tools/Systems",
                content: "If required, we can also integrate existing tools or third-party tools/ systems such as various incident management systems and process monitoring tools into the SAP Solution Manager. We follow standardized approaches to ensure that the integrations are successful and executed in the shortest possible time."
            }
        ];

        return (
            <div className="p-8 mt-12">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                    {cards.map((card, index) => (
                        <Card key={index} title={card.title} content={card.content} />
                    ))}
                </div>
            </div>
        );
    };

    const FullSuitAMS = () => {
        const services = [
            "SAP recommends an upgrade to 7.2",
            "Previous versions of Solution Manager are disconnected from SAP Backbone support",
            "Benefit from SAP's new mainstream maintenance",
            "Powerful technical monitoring capabilities",
            "Improved UX - SAP Fiori based new landscape",
            "Includes dashboard for monitoring metrics",
            "Includes dashboard for monitoring metrics (costs covered)",
            "Easy access to the latest and updated ALM tools",
            "Custom code management, change impact analysis",
            "Focused Build and Insights included",
            "Analytics on SAP HANA for free - does not need a separate license"
        ];

        return (
            <div className="text-white p-4 sm:p-8 w-11/12 mx-auto mt-8 sm:mt-12 3xl:mt-24">
                <h1 className="text-xl sm:text-2xl font-bold mb-8 sm:mb-12 xl:mb-24 text-center">
                    Why should you upgrade to Solution Manager 7.2?
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-12 mb-8 sm:mb-12 mx-auto max-w-4xl">
                    <ul className="flex flex-col items-center">
                        {services.slice(0, 6).map((service, index) => (
                            <li key={index} className="flex items-start mb-3 w-full text-xs sm:text-sm md:text-base text-[#AAAAAA]">
                                <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full mr-2 sm:mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                                {service}
                            </li>
                        ))}
                    </ul>
                    <ul className="flex flex-col items-center">
                        {services.slice(6).map((service, index) => (
                            <li key={index} className="flex items-start mb-3 w-full text-xs sm:text-sm md:text-base text-[#AAAAAA]">
                                <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full mr-2 sm:mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                                {service}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };


    const SAPDiagram = () => {
        return (
            <div className="w-full h-auto flex items-center justify-center p-4">
                <div className="relative w-48 sm:w-64 md:w-80 lg:w-[600px] h-48 sm:h-64 md:h-80 lg:h-[600px]">
                    {/* Center circle */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 sm:w-32 md:w-40 lg:w-48 h-24 sm:h-32 md:h-40 lg:h-48 rounded-full bg-gray-900 flex items-center justify-center">
                        <div className="w-24 md:w-32 lg:w-[190px] h-24 md:h-32 lg:h-[190px] rounded-full bg-gradient-to-br from-blue-500 to-purple-600 p-0.5 flex items-center justify-center">
                            <div className="w-full h-full rounded-full bg-gray-900 flex items-center justify-center">
                                <p className="text-white text-center text-sm sm:text-base  font-semibold">SAP Solution Manager</p>
                            </div>
                        </div>
                    </div>

                    {/* Surrounding cells */}
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Agile Innovation</p>
                    </div>
                    <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Cloud & Hybrid</p>
                    </div>
                    <div className="absolute bottom-0 right-1/4 transform translate-x-1/2 translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">SAP HANA</p>
                    </div>
                    <div className="absolute bottom-0 left-1/4 transform -translate-x-1/2 translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">New User Experience</p>
                    </div>
                    <div className="absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Process Manage</p>
                    </div>
                </div>
            </div>
        );
    };


    const UpgradeProcessCard = ({ title, description }) => (
        <div className="bg-[#14192E] rounded-lg p-6 py-8 flex flex-col">
            <h2 className="text-white text-xl font-bold mb-4">{title}</h2>
            <p className="text-gray-400 text-sm">{description}</p>
        </div>
    );

    const UpgradeProcessCards = () => {
        const cards = [
            {
                title: "Assessment of Current Usage Scenarios",
                description: "We check the existing usage scenarios with respect to the changes in the new releases and suggest whether an upgrade or a new installation should be done."
            },
            {
                title: "Complete Setup And Post Upgrade Activities",
                description: "We make all necessary configuration settings, initiate support communications and content activation procedure (if needed), and execute post-upgrade activities related to your specific applications."
            },
            {
                title: "Optimized Testing",
                description: "Testing and dry run of Content Activation and Solution Manager platform."
            },
            {
                title: "Technical Checks, Preparation And Execution",
                description: "We check readiness and ensure all prerequisites are in place for migration, sketch out a plan with detailed steps that should be followed as a part of the upgrade and perform and support the production upgrade procedure."
            }
        ];

        return (
            <div className=" p-8">
                <div className="max-w-6xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {cards.map((card, index) => (
                            <UpgradeProcessCard key={index} title={card.title} description={card.description} />
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const MappingYourSuccess = () => {
        return (
            <div className="text-white flex items-center justify-center p-4 mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-8 mb-12">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
                            Mapping Your Success With DIAA Corp In Diverse Ways
                        </h2>
                        <p className="text-xl md:text-2xl text-center mb-12 text-gray-400">How Solution Manager Services Can Bring Value</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
                            {[
                                { title: "Assessing your existing SAP landscape and defining the roadmap for SAP Solution Manager implementation", Icon: FaSearch },
                                { title: "Implementing SAP Solution Manager for IT Service Management and Application Lifecycle Management", Icon: FaCogs },
                                { title: "Migrating your existing SAP Solution Manager to the latest version", Icon: FaArrowCircleUp },
                                { title: "Providing SAP Solution Manager training and knowledge transfer", Icon: FaGraduationCap },
                                { title: "Offering continuous support for your SAP Solution Manager environment", Icon: FaHeadset },
                                { title: "Configuring & implementing key functionalities of SAP Solution Manager", Icon: FaWrench },
                                { title: "Defining and implementing best practices for SAP Solution Manager usage", Icon: FaClipboardCheck },
                                { title: "Integrating Solution Manager with other SAP and non-SAP systems", Icon: FaLink },
                                { title: "Creating custom reports and dashboards for better visibility and decision-making", Icon: FaChartBar },
                            ].map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                                        <div className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.Icon className="text-white text-3xl md:text-4xl lg:text-5xl" />
                                        </div>
                                        <span className="text-sm font-medium text-center text-white">{item.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const FocusedSolutions = () => {
        return (
            <div className="relative bg-indigo-900 py-24 bg-opacity-90 bg-cover bg-center" style={{ backgroundImage: `url(${SapSolutionManager_img2})`}}>
                <div className="absolute inset-0 bg-gradient-to-br from-purple-900/50 to-indigo-900/50"></div>
                <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-white">
                    <h1 className="mb-6 text-4xl font-bold text-center">
                        Focused Solutions For SAP Solution Manager
                    </h1>
                    <p className="max-w-3xl mb-12 text-center">
                        SAP Focused solutions for SAP Solution Manager: Focused Build and Focused Insights are ready to run,
                        pre-configured and automated solutions available to all SAP Solution Manager users. They are standard
                        solutions for specific requirements with the advantage of no additional coding costs, no
                        integration issues and no upgrade risks.
                    </p>
                    <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
                        {[1, 2].map((index) => (
                            <div key={index} className="flex-1 p-6 bg-gray-800 bg-opacity-50 rounded-lg backdrop-blur-sm">
                                <div className="flex items-center mb-4">
                                    <div className="w-10 h-10 mr-3 bg-purple-500 rounded-full flex items-center justify-center">
                                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                    <h2 className="text-xl font-semibold">SAP Focused Build</h2>
                                </div>
                                <p className="text-sm">
                                    contains pre-configured SAP Solution Manager functions and processes to manage requirements
                                    and software developments in large, agile projects.
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const SAPFocusedRunBenefits = () => {
        const benefits = [
            { title: "Providing your existing SAP landscape with an accurate assessment and defining the roadmap for SAP Solution Manager implementation", icon: FaSearch },
            { title: "Implementing the Solution Manager for IT Service Management and Application Lifecycle Management", icon: FaCogs },
            { title: "Reducing implementation time and costs with pre-configured best practices", icon: FaClipboardCheck },
            { title: "Enabling on-time delivery of projects with effective requirements management", icon: FaArrowCircleUp },
            { title: "Ensuring business continuity by effectively managing changes and releases", icon: FaLink },
            { title: "Maintaining IT infrastructure by proactively monitoring systems and processes", icon: FaHeadset },
            { title: "Improving IT service quality by standardizing processes and automating workflows", icon: FaWrench },
            { title: "Enhancing decision-making with real-time insights and analytics", icon: FaChartBar },
            { title: "Leveraging pre-configured SAP best practices to accelerate implementation", icon: FaGraduationCap },
        ];

        return (
            <div className="text-white flex items-center justify-center p-4 mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-8 mb-12">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
                            SAP Focused Run Benefits
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
                            {benefits.map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                                        <div className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.icon className="text-white text-3xl md:text-4xl lg:text-5xl" />
                                        </div>
                                        <span className="text-sm font-medium text-center text-white">{item.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Fine tune your SAP Landscape with SAP Solution Manager"
                description="DIAA CORP SAP Solution Manager Services can accelerate your innovation, manage your applications' lifecycle and run your enterprise solutions -- all this in one integrated platform and at no additional costs!
Talk to our experts"
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            {/* Add padding to the main content wrapper */}
            <div className=''>
                <div className='md:w-11/12 2xl:w-10/12 mx-auto'>
                    <SAPSolutionManagerCard />
                </div>

                <div className="md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        DIAA CORP SAP Solution Manager Services
                    </h2>
                    <h3 className="text-lg font-medium  mb-6">
                        Helping Optimize SAP With A Robust Roadmap
                    </h3>

                    <p className="text-base leading-7 mb-6">
                        We, at DIAA Corp, aim to provide a clear roadmap and help you get the most out of the SAP Solution Manager’s functions for optimizing your SAP projects.
                    </p>

                    <p className="text-base leading-7">
                        With our proven SAP expertise and best practices knowledge from 180+ successful project implementations/deployments, our team of experts condense, rationalize, and optimize your SAP projects, enabling your organizations to create and deploy tested solutions with minimal disruptions.
                        Solution Manager Services DIAA Corp Offers</p>

                </div>

                <div className='md:w-11/12 2xl:w-10/12 mx-auto'>
                    <SAPSolutionManagerCards />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <ContactContent title={`Leverage SAP Solution Manager to manage your IT implementation projects from Requirements to Deployments, and ensure 24/7 system availability.`} />
                </div>

                <div>
                    <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                        <h2 className="text-2xl font-semibold text-center mb-4">
                            SAP Solution Manager 7.2 Upgrade</h2>
                        <p className="text-base leading-7 mb-6">
                            We have 20 years of experience in colocation infrastructure, data center migration and security. Our scalable and dynamic SAP hosting services provide the perfect solutions with the capability to increase or decrease capacity as and when needed, and are designed to guarantee high availability and security of SAP systems. As part of our SAP Hosting Services, we take end-to-end responsibility of migrating and operating your SAP applications, letting your IT teams innovate and address crucial business requests.          </p>
                    </div>
                </div>

                <div className='mt-12 xl:mt-24 bg-[#14192E]'>
                    <div className='md:pt-4'>
                        <FullSuitAMS />
                    </div>
                    <div className='py-24'>
                        <SAPDiagram />
                    </div>
                </div>

                <div>
                    <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                        <h2 className="text-2xl font-semibold text-center mb-4">
                            How DIAA Corp Can Help</h2>
                        <p className="text-base leading-7 mb-6 max-w-7xl mx-auto text-[#F1F1F1]">
                            DIAA Corp follows a risk-free approach to upgrade SAP Solution Manager without disrupting the business operations and comply with all the SAP standards. As a part of our installation and upgrade service, we help organizations with technical upgrades to Solution Manager 7.2, New Installations, Migration to SAP HANA for Solution Manager, Content Activation, and even help with updates to latest Service Packs.</p>
                    </div>
                </div>

                <div className='mt-12 xl:mt-24'>
                    <UpgradeProcessCards />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <ContactContent title={`Why wait?`} subtitle={`Upgrade to Solution Manager 7.2 today and get the most of out of the SAP Solution Manager`} />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <MappingYourSuccess />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <FocusedSolutions />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <SAPFocusedRunBenefits />
                </div>

                <div className='mt-12 xl:mt-24 mb-12 xl:mb-24'>
                    <ContactContent title={`Experience SAP And Its Impact On Business Transformation`}/>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SAPSolutionManager