import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Energy_img1.png'
import Energy_img2 from '../../assets/Industries/Energy_img2.png'
import Energy_img3 from '../../assets/Industries/Energy_img3.png'
import Energy_img4 from '../../assets/Industries/Energy_img4.png'
import Energy_img5 from '../../assets/Industries/Energy_img5.png'
import Energy_img6 from '../../assets/Industries/Energy_img6.png'
import Energy_img7 from '../../assets/Industries/Energy_img7.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Communications_img2 from '../../assets/Capabilities/communications_img2.png'
import Communications_img3 from '../../assets/Capabilities/communications_img3.png'
import Communications_img4 from '../../assets/Capabilities/communications_img4.png'
import communications_img5 from '../../assets/Capabilities/communications_img5.png'
import communications_img6 from '../../assets/Capabilities/communications_img6.png'
import communications_img7 from '../../assets/Capabilities/communications_img7.png'
import communications_img8 from '../../assets/Capabilities/communications_img8.png'
import Communications_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { useDocumentTitle } from '../../utils/useDocumentTitle'


function Energy() {
    useDocumentTitle("Energy");
    const CommunicationsAndMediaStats = () => {
        const stats = [
            { value: '50%', description: 'of communications and media companies are investing in AI and machine learning' },
            { value: '48%', description: 'of communications and media companies are investing in cloud technologies' },
            { value: '99%', description: 'of communications and media companies are investing in data and analytics' },
            { value: '97%', description: 'of communications and media companies are investing in cybersecurity' },
        ];

        return <StatsGrid title="Energy now" stats={stats} />;
    };


    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: "Exploration and production",
                title: "Exploration and production",
                description: "Communication providers enable mobile, scalable connectivity and information sharing between consumers, businesses and governments.",
                image: Communications_img2,
            },
            {
                category: "Refining and processing",
                title: "Refining and processing",
                description: "Media and entertainment companies create, produce, and distribute content across various platforms to engage and entertain audiences.",
                image: Communications_img3,
            },
            {
                category: "Distribution and retail",
                title: "Distribution and retail",
                description: "Digital service providers offer innovative solutions and platforms that enhance connectivity, productivity, and user experiences.",
                image: Communications_img4,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Forge the future of commerce',
                description: 'Shift from setting targets to delivering tangible outcomes',
                hasButton: true,
            },
            {
                title: 'Secure the digital core and protect your physical assets',
                description: 'Supercharge operations with data and AI to remain agile and resilient',
                hasButton: true,
            },
            {
                title: 'Transform cost for resilience and growth in a volatile world',
                description: 'Powering up your work force and reinvent talent and ways of working',
                hasButton: true,
            },
            {
                title: 'Create experience for lasting loyalty',
                description: 'Build a smart supply chain for a shifting future',
                hasButton: true,
            },
            {
                title: 'Build a smart supply chain for a shifting future',
                description: 'Optimize your supply chain for agility and resilience',
                hasButton: true,
            },
            {
                title: 'Transform cost for resiliency and growth in a volatile world',
                description: 'Powering up your workforce and reinvent talent and ways of working',
                hasButton: true,
            },
            {
                title: 'Create experience for lasting loyalty',
                description: 'Build customer-centric experiences that drive loyalty and growth',
                hasButton: true,
            },
            {
                title: 'Evolve your portfolio for an agile and sustainable business',
                description: 'Adapt your business model for long-term success and sustainability',
                hasButton: true,
            },
            {
                title: 'B2C Commerce',
                description: 'Forge the future of commerce',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with sales & commerce" cards={cards} />;
    };
    const slides = [
        {
            title: "Reinvent for growth: Only the radical survive",
            image: Energy_img2,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "From survive to thrive: Tech transformation for CSPs' future",
            image: Energy_img3,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "Reinventing M&A with generative AI",
            image: Energy_img4,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];


    const CommunicationsMediaTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending in communications & media" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Fleet decarbonization: Ready to make it a corporate priority?",
                image: Energy_img5,
                alt: "Fleet decarbonization"
            },
            {
                title: "Leader in IDC MarketScape for Upstream Oil & Gas Services",
                image: Energy_img6,
                alt: "IDC MarketScape for Upstream Oil & Gas Services"
            },
            {
                title: "No. 1 Provider for Energy Services",
                image: Energy_img7,
                alt: "Energy Services"
            },
            {
                title: "Leader service providers for energy transaction",
                image: communications_img8,
                alt: "Energy transaction services"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Communications_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Communications_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Communications_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Communications_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Fuel energy resilience and reinvention"
                description="Amid rising demand, fierce competition, a push for sustainability, plus inflation and supply chain challenges, oil and gas companies need to adapt quickly to remain competitive."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsAndMediaStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventWithCloud />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsMediaTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default Energy
