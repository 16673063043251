import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/sap-analytics-cloud_cover.png'
import sapAnalyticsCloud_img1 from '../assets/SAP/sap-analytics-cloud_img1.png'
import sapAnalyticsCloud_img2 from '../assets/SAP/sap-analytics-cloud_img2.png'
import sapAnalyticsCloud_img3 from '../assets/SAP/sap-analytics-cloud_img3.png'
import sapAnalyticsCloud_img4 from '../assets/SAP/sap-analytics-cloud_img4.png'
import { Clock, DollarSign, Cloud, Globe, TrendingUp, Search, PieChart, Target, Users, MessageCircle, UserCheck, FileText } from 'lucide-react';
import { ContactContent } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SAPAnalyticsCloud() {

    useDocumentTitle("SAP Analytics");


    const SAPDrivenAnalyticsCard = () => {
        return (
            <div className="text-white p-4 sm:p-8 rounded-lg flex flex-col md:flex-row items-center">
                <div className="md:w-1/3 mr-0 md:mr-8 mb-6 md:mb-0">
                    <img
                        src={sapAnalyticsCloud_img1}
                        alt="SAP Driven Analytics"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
                <div className="md:w-2/3">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center md:text-left">
                        SAP Driven Analytics
                    </h2>
                    <p className="text-sm sm:text-base mb-4 sm:mb-6">
                        Constantly evolving business needs have left organizations expecting much more from their analytics solutions. There is a need to customize and develop new services to work efficiently with on-the-fly analysis. Organizations have an extraordinary customer and operational data. This data deluge streaming through their network needs to turn into simple, swift, and instinctive in real-time.
                    </p>
                    <p className="text-sm sm:text-base">
                        Merging our functional and industry expertise with SAP tools, DIAA Corp provides excellent customer experiences, improved operations, reduced costs, and increased revenue by delivering efficient and effective analytics solutions.
                    </p>
                </div>
            </div>
        );
    };



    const AnalyticsSolutionsPortfolio = () => {
        return (
            <div className="p-4 sm:p-8 text-white">
                <h1 className="text-3xl sm:text-4xl font-bold text-center mb-4">Our Analytics Solutions Portfolio</h1>
                <p className="text-center mb-8 text-gray-300 text-sm sm:text-base">Deploy On-premise, On Cloud or adopt a Hybrid model</p>

                <div className="space-y-6">
                    <div className="bg-custom-gradient rounded-3xl overflow-hidden shadow-lg">
                        <div className="flex flex-col md:flex-row">
                            <div className="bg-gray-900 p-6 md:w-2/5 rounded-l-3xl">
                                <h2 className="text-2xl font-semibold mb-4">SAP Analytics Cloud</h2>
                                <p className="text-sm">
                                    SAP Analytics Cloud is a Software as a Service (SaaS) business intelligence/Bigdata/IoT platform, made specifically to provide all analytical capabilities to all users in one product. We are a dedicated SAP Analytics Partner. We work with our customers to generate meaningful insights from complex data sources. Our simple business processes will help you make informed decisions that will add value to your organizational goals. DIAA Corp helps organizations leverage SAP Analytics Cloud (SAC) to visualize, plan, and predict, from one secure cloud-based environment.
                                </p>
                            </div>
                            <div className="p-6 md:w-3/5">
                                <ul className="space-y-4">
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Accumulate and transform large amounts of data to have an integral and more complete vision of the business</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Reveal hidden trends and find answers to help make decisions based on objective</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Schedule the data to refresh at any selected time for real-live data update</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Customize your data for mobile users' access</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="bg-custom-gradient rounded-3xl overflow-hidden shadow-lg">
                        <div className="flex flex-col md:flex-row">
                            <div className="bg-gray-900 p-6 md:w-2/5 rounded-l-3xl">
                                <h2 className="text-2xl font-semibold mb-4">SAP BW/4HANA</h2>
                                <p className="text-sm">
                                    BW/4HANA, coupled with SAP Data Hub, SAP Analytics Cloud (SAC), and other data analytics solutions, allows you to use your data more effectively across a wide range of business and hosting scenarios. DIAA Corp can help you deploy BW/4HANA on-premise, on-cloud, or adopt a hybrid model.
                                </p>
                            </div>
                            <div className="p-6 md:w-3/5">
                                <ul className="space-y-4">
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Adopt SAP's state-of-the-art Data Warehousing solution</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Quick transition from product-ownership to product-utilization and accelerated return-on investment</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Reduce project risk by standardized and scalable delivery from experienced teams</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Project execution at reduced time, cost, and risk compared to traditional approaches</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const BenefitCard = ({ icon, text }) => (
        <div className="flex flex-col items-center bg-gray-800 rounded-lg p-4 text-center">
            <div className="bg-custom-gradient rounded-full p-3 mb-4">
                {icon}
            </div>
            <p className="text-sm">{text}</p>
        </div>
    );

    const DIAACorpBenefits = () => {
        const benefits = [
            {
                icon: <Clock className="w-6 h-6 text-white" />,
                text: "Significant time saving compared to in-house customer-led project"
            },
            {
                icon: <DollarSign className="w-6 h-6 text-white" />,
                text: "Substantial cost efficiency due to a highly standardized delivery approach"
            },
            {
                icon: <Cloud className="w-6 h-6 text-white" />,
                text: "Superior quality delivery by experienced team in DIAA Corp SAP Migration Factory and S/4HANA CoE strategy"
            },
            {
                icon: <Globe className="w-6 h-6 text-white" />,
                text: "Access to global resources and product expertise"
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-4">
                    Quick benefits you realize with DIAA Corp
                </h2>
                <p className="text-center mb-8 max-w-3xl mx-auto">
                    DIAA Corp is committed to providing a continuous, secure and effective environment with
                    end to end management for your SAP workloads.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {benefits.map((benefit, index) => (
                        <BenefitCard key={index} icon={benefit.icon} text={benefit.text} />
                    ))}
                </div>
            </div>
        );
    };


    const DataCard = ({ percentage, description }) => (
        <div className="relative bg-[#14192E] p-6 rounded-lg overflow-hidden">
            <div className="absolute left-0 bottom-0 w-1 h-full bg-gradient-to-b from-blue-500 to-purple-500"></div>
            <div className="absolute left-0 bottom-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></div>
            <h3 className="text-5xl font-bold mb-4 bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent">
                {percentage}%
            </h3>
            <p className="text-sm text-gray-300">{description}</p>
        </div>
    );

    const SAPMasterDataGovernance = () => {
        const data = [
            {
                percentage: 50,
                description: "of CFOs expect access to clean data to better support their decision making."
            },
            {
                percentage: 30,
                description: "of world's companies are the few to measure the quality of master data."
            },
            {
                percentage: 70,
                description: "of data users at the management level experience errors or gaps in the data they use."
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-2">SAP Master Data Governance</h2>
                <p className="text-center text-gray-400 mb-8">Poor master data can be detrimental to business</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {data.map((item, index) => (
                        <DataCard key={index} percentage={item.percentage} description={item.description} />
                    ))}
                </div>
            </div>
        );
    };

    const FeatureCard = ({ title, subtitle, features }) => (
        <div className=" rounded-lg p-6 border border-theme shadow-lg relative">
            <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
            <p className="text-sm text-gray-300 mb-4">{subtitle}</p>
            <ul className="space-y-2 mt-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-white">
                        <span className="w-2 h-2 bg-theme rounded-full mr-3"></span>
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );


    const SAPServices = () => {
        const featureCards = [
            {
                title: "SAC Audit Services",
                subtitle: "Ensuring compliance and quality assurance",
                features: [
                    "Verifying licensing compliance",
                    "Compliance with industry standards",
                    "Satisfying legal requirements",
                    "Monitoring for Quality Assurance (QA)"
                ]
            },
            {
                title: "Integration With Other Tools/Systems",
                subtitle: "Seamless connectivity and support",
                features: [
                    "Proactive and preventive support across all solutions offered",
                    "Continuous support, enhancement and monitoring services and upgrade services",
                    "Implementation and support for SAP Analytics Suite",
                    "Build reports and dashboards and reports"
                ]
            }
        ];

        return (
            <div className="p-8 py-12 2xl:py-24 flex justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
                    {featureCards.map((card, index) => (
                        <FeatureCard key={index} title={card.title} subtitle={card.subtitle} features={card.features} />
                    ))}
                </div>
            </div>
        );
    };

    const KeyBenefits = () => {
        const benefits = [
            {
                icon: <TrendingUp className="w-8 h-8 text-purple-500" />,
                title: "Effectively measure & analyze",
                text: "Measure and analyze the effectiveness of your business strategies"
            },
            {
                icon: <Search className="w-8 h-8 text-purple-500" />,
                title: "Faster & deeper insights",
                text: "Gain faster and deeper insights into complex business scenarios"
            },
            {
                icon: <PieChart className="w-8 h-8 text-purple-500" />,
                title: "Clearer insights",
                text: "Clearer insights allowing for a reduction in decision-making time"
            },
            {
                icon: <Target className="w-8 h-8 text-purple-500" />,
                title: "Facilitate decision-making",
                text: "Facilitate data-driven decision-making for improved business outcomes"
            },
            {
                icon: <Users className="w-8 h-8 text-purple-500" />,
                title: "Simple to utilize",
                text: "Simple to utilize and understand"
            },
            {
                icon: <TrendingUp className="w-8 h-8 text-purple-500" />,
                title: "Effectively drives",
                text: "Effectively drives in-depth planning and forecasting"
            },
            {
                icon: <MessageCircle className="w-8 h-8 text-purple-500" />,
                title: "Spread of communication",
                text: "Spread of communication with enhanced collaboration"
            },
            {
                icon: <UserCheck className="w-8 h-8 text-purple-500" />,
                title: "Trusted and established",
                text: "Trusted and established partner"
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-4">
                    Key Benefits
                </h2>
                <p className="text-center mb-8 max-w-3xl mx-auto">
                    SAP capabilities - driving an edge in business outcomes
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="bg-[#14192E] rounded-lg p-6 flex flex-col items-center text-center">
                            <div className="mb-4">
                                {benefit.icon}
                            </div>
                            <h3 className="text-lg font-semibold mb-2">{benefit.title}</h3>
                            <p className="text-sm text-gray-300">{benefit.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const HighlightCard = ({ number, text }) => (
        <div className="bg-gradient-to-br from-[#21263B] to-[#0E102B] p-6 rounded-lg text-center w-full h-40 flex flex-col justify-center">
            <p className="text-5xl font-bold bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent mb-4">{number}</p>
            <p className="text-white text-sm">{text}</p>
        </div>
    );

    const DIAACorpHighlights = () => (
        <div className=" text-white py-16 px-8 font-sans">
            <h2 className="text-3xl font-bold mb-4 text-center">
                Highlights of DIAA Corp SAP Data and Analytics Expertise
            </h2>
            <p className="text-center mb-12 text-sm text-gray-300">
                We take pride in our team of experts and prestigious projects we have undertaken
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
                <HighlightCard number="15+" text="15+ HANA Based Projects" />
                <HighlightCard number="75+" text="Large Projects" />
                <HighlightCard number="45+" text="Experts Team" />
                <HighlightCard number="8+" text="Projects Sizes More Than 1000 PDs" />
            </div>
        </div>
    );


    const ServiceCard = ({ text }) => (
        <div className="bg-[#14192E] border border-theme/50 rounded-2xl p-4 flex items-center space-x-3 h-[72px] w-[220px]">
            <FileText className="text-purple-500" size={24} />
            <span className="text-white text-sm">{text}</span>
        </div>
    );

    const DIAACorpServices = () => (
        <div className=" text-white p-8 font-sans">
            <h2 className="text-2xl font-bold mb-6 text-center max-w-3xl mx-auto">
                DIAA CORP end-to-end services in Analytics on SAP Tech such as SAP ERP, CRM, SCM, CAR, HANA
            </h2>
            <div className="flex flex-wrap justify-center gap-4">
                <ServiceCard text="Migration & Upgrade" />
                <ServiceCard text="Performance Testing" />
                <ServiceCard text="Data Quality" />
                <ServiceCard text="Enterprise Integrations" />
                <ServiceCard text="Data Warehousing" />
                <ServiceCard text="SAP HANA Application Development" />
                <ServiceCard text="Agile Visualization" />
                <ServiceCard text="Support & Maintenance" />
                <ServiceCard text="Advanced Analytics" />
            </div>
        </div>
    );

    const CoreExpertise = () => {
        const boxes = [
            'SAP BUSINESS WAREHOUSE',
            'BW/4HANA',
            'POWERBI/SAP CAR',
            'SAP BOBJ PLATFORM',
            'SAP CLOUD ANALYTICS',
            'TABLEAU/QLIKVIEW',
            'HANA ANALYTICS',
            'WEBI/BOA ANALYTICS',
            'SAP IQ/SYBASE'
        ];

        return (
            <div className="flex flex-col items-center justify-center p-4">
                <h2 className="text-2xl font-bold text-white mb-8">Core Expertise</h2>
                <div className="flex flex-wrap justify-center gap-4 max-w-7xl">
                    {boxes.map((text, index) => (
                        <div
                            key={index}
                            className="relative bg-[#1D1F3F] text-white p-4 rounded-2xl overflow-hidden w-64 h-24 flex items-center justify-center"
                        >
                            <div className="absolute inset-0">
                                <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#4F75FF] to-[#A100FF]" />
                                <div className="absolute right-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#A100FF] to-[#4F75FF]" />
                            </div>
                            <p className="relative z-10 text-sm sm:text-base font-medium text-center px-2">{text}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Revolutionizing Business With SAP Analytics"
                description="DIAA CORP and SAP helps leading organizations exploit the power of analytics to drive efficiency of investment, keeping pace with innovation."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />

            <div className='w-11/12 mx-auto'>
                <SAPDrivenAnalyticsCard />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <AnalyticsSolutionsPortfolio />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpBenefits />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <SAPMasterDataGovernance />
            </div>

            <div className="relative bg-indigo-900 py-24 bg-opacity-90 bg-cover bg-center mt-20" style={{ backgroundImage: `url(${sapAnalyticsCloud_img2})` }}>
            <div className="absolute inset-0 bg-black opacity-10"></div>
                <div className="relative z-10 container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-white text-center mb-6">How SAP MDG Can Be Beneficial?</h2>
                            <ul className="space-y-4 text-white">
                                {[
                                    "A single trusted view of the entire enterprise's data",
                                    "Automated approval workflows for faster data creation",
                                    "Improved data quality and consistency across systems",
                                    "Central creation and maintenance of master data across SAP and non-SAP systems",
                                    "Enforce compliance with corporate data standards",
                                    "Reduce cost of data cleansing",
                                    "Improvement of data quality"
                                ].map((item, index) => (
                                    <li key={index} className="flex items-center">
                                        <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                        <span>{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-white text-center mb-6">Why DIAA Corp for SAP Master Data Governance?</h2>
                            <ul className="space-y-4 text-white">
                                {[
                                    "Cleanse, standardize, and enrich data using MDG's data quality capabilities",
                                    "We have implemented several MDG projects from blueprint to production phase",
                                    "Our consultants are well versed in the areas of MDG, Finance, Materials, Vendor, Customer, Business Partner, etc.",
                                    "Expertise to deliver projects from blueprint to production phase",
                                    "Capability to deliver projects from blueprint to production phase"
                                ].map((item, index) => (
                                    <li key={index} className="flex items-center">
                                        <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                        <span>{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className=''>
                <div className="w-11/12 text-white p-4 md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        Modernize Your Reports And Become 'Undisruptable'       </h2>
                    <h3 className="text-base sm:text-lg font-medium mb-6 text-gray-200">
                        DIAA CORP Business Consulting for SAP Data and Analytics solutions        </h3>

                    <p className="text-sm sm:text-base leading-7 mb-6">
                        At DIAA Corp, we provide business intelligence solutions and data visualization services (based on SAP Lumira) to help, rapidly integrate data from different sources, and develop key metrics for faster decisions. This, in turn, improves operational efficiency, enabling quality decision-making to stay ahead of the competition. We assist organizations to redefine their approach to reporting, strategy, technologies, and KPIs. Business users are empowered to act independently and confidently to explore, create, and analyze content in real-time producing, meaningful analytical insights from structured and unstructured data.</p>
                </div>
            </div>

            <div className='bg-[#14192E]'>
                <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                    <div className="text-white p-8 rounded-lg flex flex-col md:flex-row items-center">
                        <div className="md:w-1/3 mr-8">
                            <img
                                src={sapAnalyticsCloud_img3}
                                alt="SAP Driven Analytics"
                                className="rounded-lg w-full h-auto"
                            />
                        </div>
                        <div className="md:w-2/3 mt-6 md:mt-0">
                            <h2 className="text-3xl font-bold mb-4 text-center">
                                SAP Expert Consulting Services
                            </h2>
                            <p className="text-sm mb-6 text-gray-300">
                                Our SAP Analytics consulting services help clients transform their reporting processes by taking advantage of solutions offered by DIAA Corp. The foundation for a robust business process and analytics is possessing accurate data. We provide Data Modernization services which help in renewing businesses deliver their specific business goals by modernizing their data landscape, and creating data solutions that are future-ready, scalable, real-time, high speed, and agile.
                            </p>
                            <p className="text-sm">
                                Selection of tools based on business criteria | TCO optimization | Design and architecture consulting | License optimization |Business process outsourcing
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="w-11/12 text-white p-4 md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        Implementation Services      </h2>
                    <h3 className="text-base sm:text-lg font-medium mb-6 text-gray-300">
                        Design and Implementation - best practices and methodologies    </h3>

                    <p className="text-sm sm:text-base leading-7 mb-6">
                        DIAA CORP has the expertise in implementing, assessing, upgrading, troubleshooting, managing, and supporting SAP Analytics Solutions ranging from legacy solutions such as Crystal Reports to current and next-gen analytics solutions including SAP Business Objects, SAP Analytics Cloud, and SAP Predictive Analytics. Our expertise lies in taking the most complete, functionally rich business intelligence products from SAP and customize them for our client goals by providing reliable, high-quality solutions that help businesses achieve their goals faster.</p>
                </div>
                <div>
                    <SAPServices />
                </div>
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <KeyBenefits />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpHighlights />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpServices />
            </div>

            <div className="relative bg-indigo-900 py-24 bg-opacity-90 bg-cover bg-center mt-20" style={{ backgroundImage: `url(${sapAnalyticsCloud_img4})` }}>
            <div className="absolute inset-0 bg-black opacity-40"></div>
                <div className="relative z-10 container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center text-white mb-12">Additional Capabilities In SAP</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6 text-center lg:h-56 flex flex-col">
                            <h3 className="text-xl font-semibold text-white mb-4">SAP Enterprise Information Management</h3>
                            <p className="text-sm text-gray-300">SAP Data Hub, MDG, Data Services, SLT, Information Steward, HANA SDI</p>
                        </div>
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6 text-center lg:h-56 flex flex-col">
                            <h3 className="text-xl font-semibold text-white mb-4">SAP Enterprise Performance Management</h3>
                            <p className="text-sm text-gray-300">SAP BPC, BFC, IP, PCM, EC</p>
                        </div>
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6 text-center lg:h-56 flex flex-col">
                            <h3 className="text-xl font-semibold text-white mb-4">SAP Enterprise Data Warehousing</h3>
                            <p className="text-sm text-gray-300">SAP HANA, BW</p>
                        </div>
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6 text-center lg:h-56 flex flex-col">
                            <h3 className="text-xl font-semibold text-white mb-4">SAP Reporting</h3>
                            <p className="text-sm text-gray-300">SAP Analytics Cloud, Lumira, Discovery & Designer, BO Web Intelligence, BO Universe Design, Crystal Reports, Predictive Analytics, Analysis for OLAP, Office</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <CoreExpertise />
            </div>

            <div className='mx-auto mt-12 md:mt-20'>
                <ContactContent title={'Interested to host your SAP applications on the Cloud, with assured performance and availability?'} />
            </div>

            <Footer />
        </div>
    )
}

export default SAPAnalyticsCloud